import * as api from '../../../api/api';
import { Dispatch } from 'redux';
import { 
  SET_PROPERTY_NAMES, 
  SET_PROPERTY_CONFIGURATION 
} from '../ActionTypes';

// Action Creators
export const setPropertyConfiguration = (config: any) => ({
  type: SET_PROPERTY_CONFIGURATION,
  payload: config,
});

// Existing getproperty_names action remains unchanged
export const getproperty_names = async (dispatch: Dispatch<any>) => {
    try {
        const response = await api.postRequest('/api/rest/property_names', {});
        if(response){
            const { propertyNames } = response.data;
            if(propertyNames){
                dispatch(setPropertyNames(propertyNames));
            }
        }

    } catch (error) {
        console.log('Failed to fetch property names', error);
    }
};

// New action to get property configuration
export const get_property_configuration = (propertyName: string, dispatch: Dispatch) => {
  api.postRequest('/api/rest/get_property_configuration', { property_name: propertyName })
    .then(response => {
      dispatch(setPropertyConfiguration({
        propertyName,
        ...response.data
      }));
    })
    .catch(error => {
      console.error('Error fetching property configuration:', error);
    });
};

// Existing save_property_configuration action remains unchanged or can be updated if necessary
export const save_property_configuration = (propertyName: string, config: any, dispatch: Dispatch) => {
  api.postRequest('/api/rest/set_property_configuration', { property_name: propertyName, ...config })
    .then(response => {
      dispatch(setPropertyConfiguration({
        propertyName,
        ...response.data
      }));
    })
    .catch(error => {
      console.error('Error saving property configuration:', error);
    });
};

// Define setPropertyNames action creator
export const setPropertyNames = (propertyNames: string[]) => ({
  type: SET_PROPERTY_NAMES,
  payload: { state: propertyNames },
});

