import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import LoginPage from './Authentication/LoginPage';
import HomeScreen from './Home/HomeScreen';
import NavBar from './Navigation/NavBar';
import PropertyConfigurationPage from './PropertyConfiguration/PropertyConfigurationPage';
import AssessmentConfigurationPage from './AssessmentConfiguration/AssessmentConfigurationPage';
import ProjectRankingPage from './ProjectRanking/ProjectRankingPage';
import ProjectSummaryPage from './ProjectSummary/ProjectSummaryPage';
import EconomicAssessmentPage from './EconomicAssessment/EconomicAssessmentPage';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { refreshTokenIfNeeded } from '../state/redux/actions/AuthActions';
import PageNotFound from './404Page/404Page';

function App() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {token} = useSelector((state: any) => state.auth);
  const location = useLocation();

  useEffect(() => {
    if (!token && localStorage.getItem('token')) {
      refreshTokenIfNeeded(dispatch, navigate);
    }
  }, [navigate, location.pathname]);

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/property-configuration" element={<PropertyConfigurationPage />} />
        <Route path="/assessment-configuration" element={<AssessmentConfigurationPage />} />
        <Route path="/economic-assessment" element={<EconomicAssessmentPage />} />
        <Route path="/project-summary" element={<ProjectSummaryPage />} />
        <Route path="/project-ranking" element={<ProjectRankingPage />} />
     
        {/* 404 Route */}
        <Route path="/*" element={<PageNotFound/>} />
      </Routes>
    </div>
  );
}

export default App;
