import * as api from '../../../api/api';
import {store} from '../../reducers/globalReducers';
import { Dispatch } from 'redux';
import { SET_INITIAL_PROJECT_RANKING_DATA,SET_OTHER_DATA_FLAG_PR, SET_PROJECT_RANKING_DATA, SET_PROPERTY_ASSESSMENT_DATA, SET_PROPERTY_ASSESSMENT_NAMES_PR, SET_PROPERTY_ASSESSMENT_NAME_PR, SET_PROPERTY_NAMES_PR, SET_PROPERTY_NAME_PR } from '../ActionTypes';
import { ProjectRankingData } from '../../model/components/ProjectRankingState';

// Gets the data for a particular property name / property assessment
// This function calls our action creator to set the redux state, after pulling data from the backend/db
export const getProjectRankingData = async (dispatch: Dispatch<any>) => {
    
    try {
        const response = await api.postRequest('/api/rest/data', { });
        if(response){
            const { data } = response.data;
            if(data){
                // Dispatch to Redux store
                set_project_ranking_data(data, dispatch);
            }
        }

    } catch (error) {
        // Set error message
        console.log('Failed', error);
    }
};

// Gets all property names
// This function calls our action creator to set the redux state, after pulling data from the backend/db
export const get_property_names = async (dispatch: Dispatch<any>) => {
    
    try {
        const response = await api.postRequest('/api/rest/get-properties', { });
        if(response){
            const propertyNames = response.data.data;
            if(propertyNames){
                // Dispatch to Redux store
                dispatch(set_property_names(propertyNames));
            }
        }

    } catch (error) {
        // Set error message
        console.log('Failed', error);
    }
};

// Gets all property assessment names
// This function calls our action creator to set the redux state, after pulling data from the backend/db
export const get_property_assessment_names = async (property_name: string | null, dispatch: Dispatch<any>) => {
    
    try {
        const response = await api.postRequest('/api/rest/get-assessment-name-for-property', { property_name: property_name});
        if(response){
            const propertyAssessmentNames = response.data.data;
            if(propertyAssessmentNames){
                // Dispatch to Redux store
                set_property_assessment_names(propertyAssessmentNames, dispatch);
            }
        }

    } catch (error) {
        // Set error message
        console.log('Failed', error);
    }
};

// This gets the database data from the backend and sets it to our redux state
// This function calls our action creator to set the redux state, after pulling data from the backend/db
export const get_project_ranking_data = async (property_name: string | null, property_assessment_name: string | null, dispatch: Dispatch<any>) => {
    
    try {
        const response = await api.postRequest('/api/rest/get-project-ranking-data', { property_name: property_name, property_assessment_name: property_assessment_name});
        if(response){
            const propertyRankingData = response.data;
            if(propertyRankingData){
                //set_other_data_flag(false, dispatch) // using regular data
                // Dispatch to Redux store
                set_project_ranking_data(propertyRankingData, dispatch)
                // use this duplicate to check for edits to our data in our frontend workflow
                set_initial_project_ranking_data(propertyRankingData, dispatch)
            }
        }

    } catch (error) {
        // Set error message
        console.log('Failed', error);
    }
    
};

// Update the database with our form edits
// This function calls our action creator to set the redux state, after pulling data from the backend/db
export const send_project_ranking_data = async (project_ranking_data: ProjectRankingData | null, property_name: string | null, property_assessment_name: string | null, otherDataFlag: boolean, dispatch: Dispatch<any>) => {
    
    try {
        const ProjectRankingUpdate = {
            project_ranking_data: project_ranking_data,  // your nested data
            property_name: property_name || "",
            property_assessment_name: property_assessment_name || ""
        };

        if(otherDataFlag){
            // update other data (from different db)

        }else{
            // update regular (default) form data
            const response = await api.postRequest('/api/rest/set-project-ranking-data', ProjectRankingUpdate );
            if(response){
                const propertyRankingData = response.data.data;
                if(propertyRankingData){
                    // Dispatch to Redux store
                    set_project_ranking_data(propertyRankingData, dispatch)
                    // use this duplicate to check for edits to our data in our frontend workflow
                    set_initial_project_ranking_data(propertyRankingData, dispatch)
                }
            }
        }


    } catch (error) {
        // Set error message
        console.log('Failed', error);
    }
    
};

// Get form data for different db
// This function calls our action creator to set the redux state, after pulling data from the backend/db
export const get_project_ranking_data_other_data = async (property_name: string | null, property_assessment_name: string | null, dispatch: Dispatch<any>) => {
    
    try {
        const response = await api.postRequest('/api/rest/get-project-ranking-data-other-data', { property_name: property_name, property_assessment_name: property_assessment_name});
        if(response){
            const propertyRankingData = response.data;
            if(propertyRankingData){
                //set_other_data_flag(true, dispatch) // Using "load data from db" data
                // Dispatch to Redux store
                set_project_ranking_data(propertyRankingData, dispatch)
                // use this duplicate to check for edits to our data in our frontend workflow
                //set_initial_project_ranking_data(propertyRankingData, dispatch)
            }
        }

    } catch (error) {
        // Set error message
        console.log('Failed', error);
    }
};

// otherDataFlag - This flag notifies the app that we are using different data from a different db (need this flag for when we update the form data)
// this sets the redux state
export const set_other_data_flag = (otherDataFlag: boolean, dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_OTHER_DATA_FLAG_PR,
      payload: otherDataFlag,
    });
  };


// projectRankingData variable - holds most/all the form field data
// this sets the redux state
export const set_project_ranking_data = async (projectRankingData: ProjectRankingData | null, dispatch: Dispatch<any>) => {
    dispatch({
        type: SET_PROJECT_RANKING_DATA,
        payload: {
            state: projectRankingData
        }
    });
    
};

// initialProjectRankingData variable - copy of most/all the form field data (projectRankingData), for keeping track of changes/edits
// this sets the redux state
export const set_initial_project_ranking_data = (initialProjectRankingData: ProjectRankingData | null, dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_INITIAL_PROJECT_RANKING_DATA,
      payload: initialProjectRankingData,
    });
  };

// propertyAssessmentNames variable - list of all the property assesmnet names for a particular property
// this sets the redux state
export const set_property_assessment_names = (propertyAssessmentNames: string, dispatch: Dispatch<any>): any => {
    dispatch({
        type: SET_PROPERTY_ASSESSMENT_NAMES_PR,
        payload: {
            state: propertyAssessmentNames
        }
    });
};

// propertyAssessmentName variable - keeps track of property assessment name we selected
// this sets the redux state
export const set_property_assessment_name = (propertyAssessmentName: string | null, dispatch: Dispatch<any>): any => {
    dispatch({
        type: SET_PROPERTY_ASSESSMENT_NAME_PR,
        payload: {
            state: propertyAssessmentName
        }
    });
};

// propertyNames variable - list of all property names
// New action creator for setting property names
export const set_property_names = (propertyNames: string[]): any => {
    return {
        type: SET_PROPERTY_NAMES_PR,
        payload: {
            state: propertyNames
        }
    };
};

// propertyName variable - the property name we selected
// this function sets the redux state
export const set_property_name = (propertyName: string | null, dispatch: Dispatch<any>): any => {
    dispatch({
        type: SET_PROPERTY_NAME_PR,
        payload: {
            state: propertyName
        }
    });
};