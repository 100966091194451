interface Action<T = any> {
    type: string;
    payload?: {
        state?: T;
    };
    [key: string]: any;  // Index signature allowing additional properties
}


export const changeHeight = (state: number): Action => {
    return {
        type: 'CHANGE_HEIGHT',
        payload: {
            state: state
        }
    };
};

export const changeWidth = (state: number): Action => {
    return {
        type: 'CHANGE_WIDTH',
        payload: {
            state: state
        }
    };
};

export const toggleOpenCloseMenu = (state: boolean): Action => {
    return {
        type: 'TOGGLE_OPEN_CLOSE_MENU',
        payload: {
            state: state
        }
    };
};

export const reRouteApp = (state: string | null): Action => {
    return {
        type: 'REROUTE_APP',
        payload: {
            state: state
        }
    };
};

export const retryRefreshCount = (state: number): Action => {
    return {
        type: 'REFRESH_COUNT',
        payload: {
            state: state
        }
    };
};