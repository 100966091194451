import axios, { AxiosRequestConfig } from 'axios';
import {store} from '../redux/reducers/globalReducers.js'
import { loadingRefresh, loginUser, logoutUser } from '../redux/actions/AuthActions';
import { reRouteApp, retryRefreshCount } from '../redux/actions/GeneralStateActions';
import { IS_LOADING_REFRESH } from '../redux/actions/ActionTypes';

// Make sure we request to the right server
// In development environment the path is http://localhost:8000 
// but in production it is https://rgldapp.com
const path = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:8000` : ''}`;
const API = axios.create({ baseURL: path});
// Dynamically makes the request to the python server depending on the parmaters
export const postRequest = (path: string, data: any, blob = false) => {
  store.dispatch(retryRefreshCount(0))
  // Check if 'blob' is true, and set responseType accordingly
  const config: AxiosRequestConfig = blob ? { responseType: 'blob' } : {}; 
  return API.post(path, data, config)
};

// Dynamically makes a GET request to the python server depending on the parameters
export const getRequest = (path: string, params?: any) => {
  store.dispatch(retryRefreshCount(0))
  return API.get(path, { params })
};

// Adds our auth token to the request header
API.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }
);

// Intercept the response if its not a 200. If its a 400 (unauthorized), check the token and try again, otherwise reroute to login screen
API.interceptors.response.use((response) => response, async (error) => {
    // turn loading wheel on
    store.dispatch(loadingRefresh(true));
    const count = store.getState().general.refreshCount

    if(error){
        // 401 unauthorized, try to refresh token, ensure we havnt tried already
        const originalRequest = error.config;

        if (error.response.status === 401 && count === 0) {
            
            store.dispatch(retryRefreshCount(count+1))

            const username = localStorage.getItem('username') || store.getState().auth.username;
            const refreshToken = localStorage.getItem('refreshToken') || store.getState().auth.refreshToken;
            
            if (refreshToken && username) {

                try {
                    
                    const response = await postRequest('/api/auth/refresh-token', {refreshToken, username});
                    const { AccessToken, IdToken } = response.data;
                    if(AccessToken && IdToken){

                        loginUser(AccessToken, refreshToken, IdToken, username, store.dispatch)
                    }
                    // Retry the original request
                    originalRequest.headers.Authorization = `Bearer ${AccessToken}`;
                    store.dispatch(retryRefreshCount(0))
                    store.dispatch(loadingRefresh(false));
                    return API(originalRequest);

                } catch (refreshError) {

                    console.log('Token refresh failed', refreshError);
                    logoutUser(store.dispatch)
                    store.dispatch(reRouteApp('/login'));

                }

            } else {
                
                console.log('No refresh token', error);
                logoutUser(store.dispatch)
                store.dispatch(reRouteApp('/login'));

            }
        }

    }else if(error.response.status === 401 && count > 0){

        // weve already tried
        logoutUser(store.dispatch)
        store.dispatch(reRouteApp('/login'));
    }

    store.dispatch(loadingRefresh(false));
    return Promise.reject(error);
    }
);