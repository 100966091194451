import * as api from '../../api/api';
import { AuthState } from '../model/AuthState';
import {store} from '../reducers/globalReducers';
import { Dispatch } from 'redux';
import {jwtDecode} from 'jwt-decode';

export const loginUserWithNav = async (username: string, password: string, width: number, height: number, dispatch: Dispatch<any>, navigate: any) => {
    try {
        dispatch(loadingLogin(true));
        const response = await api.postRequest('/api/auth/login', { username, password, width, height });
        if(response){
            const { AccessToken, RefreshToken, IdToken } = response.data;
            if(AccessToken && RefreshToken && IdToken){
                loginUser(AccessToken, RefreshToken,IdToken, username, dispatch)
                navigate('/home'); // Redirect to a secure route after login
            }
        }
        dispatch(authErrorMessage(null));
        dispatch(loadingLogin(false));
    } catch (error: any) {
        // Extracting the number of failed login attempts from the error response
        let errorMessage = 'Login failed, ensure you are using the right username and password.';
        
        if (error.response && error.response.status === 401 && error.response.data.detail) {
            const failedAttempts = error.response.data.detail;
            if(failedAttempts === 5){
                errorMessage = `Login failed: ${failedAttempts} \nYour IP is now blocked. Contact the admins if this is a mistake.`;
            }else{
                errorMessage = `Login failed: ${failedAttempts} \nYour IP will be blocked after 5 failed login attempts.`;
            }
        } else {
            errorMessage = error.message || 'An error occurred while logging in.';
        }

        // Set error message with failed attempts info
        dispatch(authErrorMessage(errorMessage));
        dispatch(loadingLogin(false));
        console.log('Login failed', error);
    }
};


// Refresh token logic
export const refreshTokenIfNeeded = async (dispatch: Dispatch<any>, navigate: Function) => {
    
    // Assuming the types for the decoded token
    interface DecodedToken {
        iat: number;
        exp: number;
        [key: string]: any;
    }

    try {
        const username = localStorage.getItem('username') || store.getState().auth.username;
        const refreshToken = localStorage.getItem('refreshToken') || store.getState().auth.refreshToken;
        const accessToken = localStorage.getItem('token')
        const idToken = localStorage.getItem('idToken')
        
        const decodedToken: DecodedToken = jwtDecode(accessToken ?? '');
        const currentTime = Date.now() / 1000;
        const bufferTime = 5 * 60; // 5 minutes buffer

        // if token is expired or expiring in 5 mins, get a new one with the refresh token
        if((decodedToken.exp < currentTime + bufferTime)){
            
            dispatch(loadingRefresh(true));

            if(refreshToken && username){
                const response = await api.postRequest('/api/auth/refresh-token', { refreshToken, username });
                const { AccessToken, IdToken } = response.data;

                if(AccessToken && IdToken){
                    loginUser(AccessToken, refreshToken, IdToken, username, dispatch)
                }
            }
            
            dispatch(loadingRefresh(false));

        }else{
            
            // token is not expired
            // just save our local storage to redux state
            loginUser(accessToken, refreshToken, idToken, username, dispatch)
        
        }

    } catch (error) {
        // logout (delete tokens): either your refresh token expired (30 days) or somethings sketchy with your local storage
        dispatch(loadingRefresh(false));
        console.log('Token refresh failed, 30 days is probably up. Please login again.', error);
        logoutUser(dispatch)
        navigate('/login');
    }

};


export const logoutUserWithNav = async (dispatch: any, navigate: any) => {
    // Dispatch to Redux store
    dispatch(loadingLogout(true));

    dispatch(setToken(null));
    dispatch(setRefreshToken(null));
    dispatch(setIdToken(null));
    dispatch(setUsername(null));
    await localStorage.removeItem('token');
    await localStorage.removeItem('refreshToken');
    await localStorage.removeItem('idToken');
    await localStorage.removeItem('username');
    navigate('/login')

    dispatch(loadingLogout(false));

};

export const logoutUser = (dispatch: any) => {
    // Dispatch to Redux store
    dispatch(setToken(null));
    dispatch(setRefreshToken(null));
    dispatch(setIdToken(null));
    dispatch(setUsername(null));
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('username');
};

export const loginUser = (AccessToken: string | null, RefreshToken: string | null, IdToken: string | null, username: string | null, dispatch: any) => {
    // Save tokens to local storage
    localStorage.setItem('token', AccessToken ?? '');
    localStorage.setItem('refreshToken', RefreshToken ?? '');
    localStorage.setItem('idToken', IdToken ?? '');
    localStorage.setItem('username', username ?? '');

    // Dispatch to Redux store
    dispatch(setToken(AccessToken));
    dispatch(setRefreshToken(RefreshToken));
    dispatch(setIdToken(IdToken));
    dispatch(setUsername(username));
};

export const loadingLogin = (state: boolean): any => {
    return {
        type: 'IS_LOADING_LOGIN',
        payload: state
    };
};

export const loadingLogout = (state: boolean): any => {
    return {
        type: 'IS_LOADING_LOGOUT',
        payload: state
    };
};

export const loadingRefresh = (state: boolean): any => {
    return {
        type: 'IS_LOADING_REFRESH',
        payload: state
    };
};

export const loadingValidate = (state: boolean): any => {
    return {
        type: 'IS_LOADING_VALIDATE',
        payload: state
    };
};

export const authErrorMessage = (state: string | null): any => {
    return {
        type: 'SET_AUTH_ERROR_MESSAGE',
        payload: state
    };
};

export const setUsername = (state: string | null): any => {
    return {
        type: 'SET_USERNAME',
        payload: state
    };
};

export const setToken = (state: string | null): any => {
    return {
        type: 'SET_TOKEN',
        payload: state
    };
};


export const setRefreshToken = (state: string | null): any => {
    return {
        type: 'SET_REFRESH_TOKEN',
        payload: state
    };
};


export const setIdToken = (state: string | null): any => {
    return {
        type: 'SET_ID_TOKEN',
        payload: state
    };
};
