import { ProjectRankingData } from '../state/redux/model/components/ProjectRankingState';
import * as XLSX from 'xlsx';

function sanitizeCellValue(value: string | number | null | undefined): string | number {
    return value !== null && value !== undefined ? value : '';
}

export function createExcel(projectRankingData: ProjectRankingData): void {
    try {
        if (projectRankingData) {
            const {
                potential_data,
                current_data,
                comments
            } = projectRankingData;

            // Prepare the rows for the Excel sheet
            const rows = [
                ['Metric','Technical Score', 'Commercial Score', 'Comments'],  // Column headers
                ['Jurisdiction', sanitizeCellValue(current_data?.Jurisdiction), sanitizeCellValue(potential_data?.Jurisdiction), sanitizeCellValue(comments?.Jurisdiction_comments)],
                ['Annual Production (AuEQ)', sanitizeCellValue(current_data?.AuEq_Annual_Production), sanitizeCellValue(potential_data?.AuEq_Annual_Production), sanitizeCellValue(comments?.Annualaueq_comments)],
                ['Metal Type', sanitizeCellValue(current_data?.Metal_Type), sanitizeCellValue(potential_data?.Metal_Type), sanitizeCellValue(comments?.Metaltype_comments)],
                ['Gross Margin (%)', sanitizeCellValue(current_data?.Gross_Margin), sanitizeCellValue(potential_data?.Gross_Margin), sanitizeCellValue(comments?.Grossmargin_comments)],
                ['Company Financial Strength', sanitizeCellValue(current_data?.Company_Financial_Strength), sanitizeCellValue(potential_data?.Company_Financial_Strength), sanitizeCellValue(comments?.Companyfinstrength_comments)],
                ['Company / Team', sanitizeCellValue(current_data?.Company_Team), sanitizeCellValue(potential_data?.Company_Team), sanitizeCellValue(comments?.Companyteam_comments)],
                ['Project Stage', sanitizeCellValue(current_data?.Stage), sanitizeCellValue(potential_data?.Stage), sanitizeCellValue(comments?.Stage_comments)],
                ['Permitting Status', sanitizeCellValue(current_data?.Permitting), sanitizeCellValue(potential_data?.Permitting), sanitizeCellValue(comments?.Permitting_comments)],
                ['ESG (Water)', sanitizeCellValue(current_data?.ESG_Water), sanitizeCellValue(potential_data?.ESG_Water), sanitizeCellValue(comments?.ESG_Water_Comments)],
                ['ESG (Community)', sanitizeCellValue(current_data?.ESG_Community), sanitizeCellValue(potential_data?.ESG_Community), sanitizeCellValue(comments?.ESG_Community_Comments)],
                ['ESG (GHG Emissions)', sanitizeCellValue(current_data?.ESG_GHG), sanitizeCellValue(potential_data?.ESG_GHG), sanitizeCellValue(comments?.ESG_GHG_Comments)],
                ['Reserves / Mineable Tonnage', sanitizeCellValue(current_data?.Reserves), sanitizeCellValue(potential_data?.Reserves), sanitizeCellValue(comments?.Reserves_comments)],
                ['Resource Conversion Exploration Upside', sanitizeCellValue(current_data?.Resource_Conversion_Exploration), sanitizeCellValue(potential_data?.Resource_Conversion_Exploration), sanitizeCellValue(comments?.Upside_comments)],
                ['Deposit Shape / Complexity', sanitizeCellValue(current_data?.Deposit_Type), sanitizeCellValue(potential_data?.Deposit_Type), sanitizeCellValue(comments?.Deposit_comments)],
                ['Mining Complexity', sanitizeCellValue(current_data?.Mine_Complexity), sanitizeCellValue(potential_data?.Mine_Complexity), sanitizeCellValue(comments?.Miningcomplexity_comments)],
                ['Process Complexity', sanitizeCellValue(current_data?.Process_Complexity), sanitizeCellValue(potential_data?.Process_Complexity), sanitizeCellValue(comments?.Processingcomplexity_comments)],
                ['Infrastructure', sanitizeCellValue(current_data?.Infrastructure), sanitizeCellValue(potential_data?.Infrastructure), sanitizeCellValue(comments?.Infrastructure_comments)],
            ];

            // Create a new workbook and add a sheet
            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Project Ranking');

            // Export to Excel
            XLSX.writeFile(workbook, 'project_qualitative_ranking.xlsx');
            console.log('Excel file successfully saved.');
        }
    } catch (e) {
        console.log("Error generating Excel file: " + e);
    }
}
