import React, { useEffect, useState } from 'react';
import { RootState } from '../../state/redux/model/RootState';
import { useSelector, useDispatch } from 'react-redux';
import { get_property_names, set_property_name, get_property_assessment_names, set_property_assessment_name, get_project_ranking_data, set_project_ranking_data, set_initial_project_ranking_data, send_project_ranking_data, get_project_ranking_data_other_data, set_other_data_flag } from '../../state/redux/actions/components/ProjectRankingActions';
import "../../assets/css/ProjectRanking.css"
import { AnnualAuEqProduction, CompanyFinancialStrength, CompanyTeam, DepositType, ESGCommunity, ESGGHG, ESGWater, GrossMargin, Infrastructure, Jurisdiction, MetalType, MiningComplexity, Permitting, ProcessingComplexity, PropertyAssessmentName, PropertyName, Reserves, ResourceSizeAuEq, ResourcesExploration, Stage } from './SubComponents/Rows';
import { ProjectRankingData } from '../../state/redux/model/components/ProjectRankingState';
import { createPDF, handleDownloadScoringGuidelines } from '../../functions/pdf';
import ProjectRankingPlot from './SubComponents/Plot';
import { createExcel } from '../../functions/excel';
import PDFContainer from './SubComponents/PDFContainer';

const ProjectRankingPage = () => {

  const {propertyNames, propertyName, propertyAssessmentNames, projectRankingData, initialProjectRankingData, propertyAssessmentName, otherDataFlag} = useSelector((state: RootState)=>state.project_ranking)
  const dispatch = useDispatch()
  const { token } = useSelector((state: any) => state.auth);
  const [isExpanded1, setIsExpanded1] = useState<boolean>(false);
  const [isExpanded2, setIsExpanded2] = useState<boolean>(false);
  const [isProduction, setIsProduction] = useState<boolean>(true)
  const [kOz, setkOz] = useState<number | undefined | null>()
  const [percentGM, setPercentGM] = useState<number | undefined | null>()
  const [showUpdateRecordPopup, setShowUpdateRecordPopup] = useState<boolean>(false)
  const [showPlot, setShowPlot] = useState(false)
  const [showScoringGuidelines, setShowScoringGuidelines] = useState(false)

  useEffect(()=>{ 
    // load our data if we dont have it yet, on component mount
    if(propertyName === null || propertyAssessmentName === null || projectRankingData === null){
      get_property_names(dispatch)
    }
  },[])

  // when we load the page, the propertynames are loaded.
  // Once thats in redux state we listen for the list of property names. 
  // Take the first property in the list (Abujar) and get all its data
  useEffect(()=>{ 
    if(propertyNames && propertyNames.length > 0  && propertyName === null){
      setPropertyName(propertyNames[0])
    }
  },[propertyNames])

  // Effect to automatically set the first item when the list of property assessment names is received
  useEffect(() => {
    if (propertyAssessmentNames && propertyAssessmentNames.length > 0 && propertyAssessmentName === null) {
      setPropertyAssessmentName(propertyAssessmentNames[0]);
    }
  }, [propertyAssessmentNames]); // Runs when propertyAssessmentNames is updated

  const setPropertyName = (value: any) => {

    setPropertyAssessmentName(null);
    set_property_name(value, dispatch)
    get_property_assessment_names(value, dispatch)

  }

  const setPropertyAssessmentName = (value: any) => {

    set_property_assessment_name(value, dispatch)
    get_project_ranking_data(propertyName, value, dispatch)
  }

  // Handler to update the large object dynamically
  const handleInputChange = <K extends keyof ProjectRankingData>(
    field: K,
    subField: string,
    value: any
  ) => {
    // Check if projectRankingData exists and field is valid
    if (projectRankingData && projectRankingData[field]) {
      // Copy the existing projectRankingData
      const updatedData = {
        ...projectRankingData,
        [field]: {
          ...projectRankingData[field as K],  // Spread the existing data for the field
          [subField]: value                   // Update the specific sub-field
        }
      };

      // Dispatch action to update Redux state with the updated object
      set_project_ranking_data(updatedData, dispatch);
    }
  };

  // expand the calc button
  const toggleExpand1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  // expand the calc button
  const toggleExpand2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  const clearFields = () => {
    set_project_ranking_data(null, dispatch);
    set_initial_project_ranking_data(null, dispatch);
    setkOz(null)
    setPercentGM(null)
    setIsExpanded1(false)
    setIsExpanded2(false)
    //set_other_data_flag(false, dispatch)
  }

  const calculateScore = () => {

    if(kOz && projectRankingData){
      let score = 0;
      switch (true) {
        case (kOz >= 0 && kOz < 15):
          score = 0;
          break;
        case (kOz >= 16 && kOz < 35):
          score = 1;
          break;
        case (kOz >= 35 && kOz < 50):
          score = 2;
          break;
        case (kOz >= 50 && kOz < 75):
          score = 3;
          break;
        case (kOz >= 75 && kOz < 99):
          score = 4;
          break;
        case (kOz >= 99 && kOz < 133):
          score = 5;
          break;
        case (kOz >= 133 && kOz < 166):
          score = 6;
          break;
        case (kOz >= 166 && kOz < 199):
          score = 7;
          break;
        case (kOz >= 199 && kOz < 300):
          score = 8;
          break;
        case (kOz >= 300 && kOz < 400):
          score = 9;
          break;
        default:
          score = 10;
      }
      handleInputChange('potential_data', 'AuEq_Annual_Production', score)
    }

  }

  const calculateScoreGM = () => {
    
    if(percentGM && projectRankingData){
      let score = 0;    
      const grossMarginForScore = percentGM/100;
      if (isProduction) {
        switch (true) {
          case (grossMarginForScore >= 0 && grossMarginForScore < 0.02):
            score = 0;
            break;
          case (grossMarginForScore >= 0.02 && grossMarginForScore < 0.05):
            score = 1;
            break;
          case (grossMarginForScore >= 0.05 && grossMarginForScore < 0.15):
            score = 2;
            break;
          case (grossMarginForScore >= 0.15 && grossMarginForScore < 0.175):
            score = 3;
            break;
          case (grossMarginForScore >= 0.175 && grossMarginForScore < 0.20):
            score = 4;
            break;
          case (grossMarginForScore >= 0.20 && grossMarginForScore < 0.233):
            score = 5;
            break;
          case (grossMarginForScore >= 0.233 && grossMarginForScore < 0.266):
            score = 6;
            break;
          case (grossMarginForScore >= 0.266 && grossMarginForScore < 0.30):
            score = 7;
            break;
          case (grossMarginForScore >= 0.30 && grossMarginForScore < 0.35):
            score = 8;
            break;
          case (grossMarginForScore >= 0.35 && grossMarginForScore < 0.40):
            score = 9;
            break;
          default:
            score = 10;
        }
      } else if (isProduction === false) {
        switch (true) {
          case (grossMarginForScore >= 0 && grossMarginForScore < 0.07):
            score = 0;
            break;
          case (grossMarginForScore >= 0.07 && grossMarginForScore < 0.10):
            score = 1;
            break;
          case (grossMarginForScore >= 0.10 && grossMarginForScore < 0.20):
            score = 2;
            break;
          case (grossMarginForScore >= 0.20 && grossMarginForScore < 0.25):
            score = 3;
            break;
          case (grossMarginForScore >= 0.25 && grossMarginForScore < 0.30):
            score = 4;
            break;
          case (grossMarginForScore >= 0.30 && grossMarginForScore < 0.333):
            score = 5;
            break;
          case (grossMarginForScore >= 0.333 && grossMarginForScore < 0.366):
            score = 6;
            break;
          case (grossMarginForScore >= 0.366 && grossMarginForScore < 0.40):
            score = 7;
            break;
          case (grossMarginForScore >= 0.40 && grossMarginForScore < 0.45):
            score = 8;
            break;
          case (grossMarginForScore >= 0.45 && grossMarginForScore < 0.50):
            score = 9;
            break;
          default:
            score = 10;
        }
      } else {
        score = 999;
      }

      handleInputChange('potential_data', 'Gross_Margin', score);
    }
  };

  const updateProjectRankingData = () => {

    setShowUpdateRecordPopup(false)
    // Send data to backend
    send_project_ranking_data(projectRankingData, propertyName, propertyAssessmentName, otherDataFlag, dispatch)
  }

  // if our data has been cleared, and we select the dropdown menu, reload the data
  const reload = (value: any) => {

    if(!projectRankingData && propertyAssessmentName){
      setPropertyAssessmentName(propertyAssessmentName)
    }
  }
  

  function handleBackgroundClick(event: any) {
    // Check if the click happened on the background and not on the container
    if (event.target === event.currentTarget) {
        // Close the popup
        setShowPlot(false)
    }
}

// Function to detect if the browser is on a mobile device
const isMobile = () => {
  console.log('UserAgent:', navigator.userAgent);  // Log user agent to verify
  return /Mobi|Android/i.test(navigator.userAgent);
};

  return (
    token &&
    <div className='background small-font'>
      <h1 className='margin-top'>Project Ranking</h1>
      
      <PropertyName propertyNames={propertyNames} setPropertyName={setPropertyName} reload={reload}/>

      <PropertyAssessmentName propertyAssessmentNames={propertyAssessmentNames} setPropertyAssessmentName={setPropertyAssessmentName} reload={reload}/>

      <div className='row-left-pr mt-20'>
        <div className='column-1-pr column-title'>
            Commercial Scores
        </div>
        <div className='column-2-pr'>
            Current Scores
        </div>
        <div className='column-3-pr'>
            Potential Scores
        </div>
        <div className='column-4-pr'>
            Comments
        </div>
      </div>

      <Jurisdiction projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <AnnualAuEqProduction projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} toggleExpand1={toggleExpand1} isExpanded1={isExpanded1}/>

      <div className={`row-left-pr ${isExpanded1 ? 'expanded-div-pr' : 'hidden-div-pr'}`}>
        <div className="column-1-pr">
          Calc New Annual AuEq Production
        </div>
        
        {/* Calc Score Button */}
        <div className="column-2-pr">
          <div 
            className={`button-pr ${kOz && projectRankingData ? '' : 'disabled-pr' }`} 
            onClick={()=>calculateScore()}
          >
          Calc Score
          </div>
        </div>

        {/* New Score */}
        <div className="column-3-pr">
          <input 
            className='projected-score-pr' 
            type="number" 
            value={kOz ?? ""}
            onChange={(e)=>setkOz(parseFloat(e.target.value))}
            placeholder={"K oz"}
            />
        </div>

        {/* Dropdown */}
        <div className="column-4-pr relative-col4-pr" />

      </div>

      <MetalType projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <GrossMargin projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} toggleExpand2={toggleExpand2} isExpanded2={isExpanded2}/>

      <div className={`row-left-pr ${isExpanded2 ? 'expanded-div-pr' : 'hidden-div-pr'}`}>
          <div className="column-1-pr">
            Calc New Gross Margin
          </div>
          
          {/* Calc Score Button */}
          <div className="column-2-pr">
            <div 
              className={`button-pr ${percentGM && projectRankingData ? '' : 'disabled-pr' }`} 
              onClick={()=>calculateScoreGM()}
            >
            Calc Score
            </div>
          </div>

          {/* New Score */}
          <div className="column-3-pr">
            <input 
              className='projected-score-pr' 
              type="number" 
              value={percentGM ?? ""}
              onChange={(e)=>setPercentGM(parseFloat(e.target.value))}
              placeholder={"%"}
              />
          </div>

          {/* Dropdown */}
          <div className="column-4-pr relative-col4-pr">
            <select className='dropdown-metal-pr ml-0' onChange={(e)=>{e.target.value === 'Production' ? setIsProduction(true) : setIsProduction(false)}}>
                <option>
                  Production
                </option>
                <option>
                  Development
                </option>
              </select>
          </div>

      </div>

      <CompanyFinancialStrength projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <CompanyTeam projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <div className='row-left-pr mt-20'>
        <div className='column-1-pr column-title'>
            Technical Scores
        </div>
        <div className='column-2-pr'>
            
        </div>
        <div className='column-3-pr'>
            
        </div>
        <div className='column-4-pr'>
            
        </div>
      </div>
      
      <Stage projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <Permitting projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <ESGWater projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <ESGCommunity projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <ESGGHG projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />
      
      <Reserves projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <ResourcesExploration projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <DepositType projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <MiningComplexity projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <ProcessingComplexity projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <Infrastructure projectRankingData={projectRankingData} initialProjectRankingData={initialProjectRankingData} handleInputChange={handleInputChange} />

      <ResourceSizeAuEq projectRankingData={projectRankingData} handleInputChange={handleInputChange} />

      <div className='button-container-pr mt-20'>
          <div className='button-item-pr' onClick={()=>clearFields()}>
                Clear Fields
          </div>
        
          <div className={`button-item-pr ${(projectRankingData !== initialProjectRankingData) && !otherDataFlag ? 'highlighted-input-pr' : 'disabled-pr'}`} onClick={()=>{!otherDataFlag && setShowUpdateRecordPopup(true)}}>
            Update Records (Existing)
          </div>
      </div>

      <div className='button-container-pr mt-20'>
        <div className='button-item-pr' onClick={()=>{projectRankingData && createPDF(projectRankingData)}}>
          Create Matrix For Presentation
        </div>

        <div className='button-item-pr' onClick={()=>{projectRankingData && createExcel(projectRankingData)}}>
          Export to Excel
        </div>

        <div className='button-item-pr' onClick={()=>{setShowPlot(true)}}>
          Plot Results
        </div>

        <div className='button-item-pr' onClick={()=>get_project_ranking_data_other_data(propertyName, propertyAssessmentName, dispatch)}>
          Load Data From DB
        </div>
  
        <div className='button-item-pr' onClick={()=>isMobile() ? setShowScoringGuidelines(true) : handleDownloadScoringGuidelines()}>
          Launch Scoring Guidelines
        </div>

      </div>

      <div className='space-pr'/>

      {showUpdateRecordPopup&&
      <div className='popup-background-pr'>
        <div className='popup-container-pr'>
          <div className='popup-title-pr'>
            Are you sure?
          </div>
          <p className='sub-msg-pr'>This will overwrite all data with the values you have here, even if empty.</p>
          <div className='row-centered-pr'>
            <div className='popup-button-pr' onClick={()=>{setShowUpdateRecordPopup(false)}}>
              Cancel
            </div>
            <div className='popup-button-green-pr' onClick={()=>{!otherDataFlag && updateProjectRankingData()}}>
              Update 
            </div>
          </div>
        </div>
      </div>}

      {showPlot &&
      <div className='popup-background-pr' onClick={(e)=>handleBackgroundClick(e)}>
        <div className='plot-container'>
          <ProjectRankingPlot />
        </div>
      </div>}
      {showScoringGuidelines &&
        <PDFContainer />
      }
    </div>
  );
};

export default ProjectRankingPage;
