import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../assets/css/Login.css"
import padlock from "../../assets/images/padlock-white.png"
import eye from "../../assets/images/eye-white.png"
import logo from "../../assets/images/rg.png"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { authErrorMessage, loginUserWithNav } from '../../state/redux/actions/AuthActions';
import TermsOfUse from '../Navigation/TermsOfUse';
import Hamburger from 'hamburger-react';
import { RootState } from '../../state/redux/model/RootState';

const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const {errorMessageLogin, isLoadingLogin, token} = useSelector((state: any)=>state.auth)
  const {width, height } = useSelector((state:RootState) => state.general)
  const dispatch = useDispatch();
  const [termsOfUsePopup, setTermsOfUsePopup] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [username, password]);

  const handleLogin = async () => {
    // Login logic here
    if(username != '' && password != ''){
      await loginUserWithNav(username, password, width, height, dispatch, navigate)
    }else{
      dispatch(authErrorMessage("You need to enter a username and password"))
    }
  };

  const handleChangeUsername = (e: any) => {
    setUsername(e.target.value)
  }

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value)
  }

  const routeTo = (path: string) => {
        
    if(token){
        navigate(path);
    }

  };

  return (
    <div className='background'>
      {!token ?
      <>
        <div className='title-row'>
          <img src={padlock} className='title-icon'/>
          <h1 className='title-text'>Login Securely</h1>
        </div>
      
        {isLoadingLogin?
          <div className='loading-wheel-contianer'>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          :
          <>
            <input className='login-username' placeholder='Username' onChange={(e)=>handleChangeUsername(e)} value={username}/>

            <div className={'password-row'}>
              <input className={'login-password'} placeholder="Password" onChange={(e)=>handleChangePassword(e)} type={showPassword ? 'text' : 'password'} value={password} />
              <div className={'show-password'} onClick={()=>{setShowPassword(!showPassword)}}>
                <img src={eye} className='icon-show-password'/>
              </div>
            </div>

            <div className={'button-login'} onClick={()=>{handleLogin()}}>
              Login
            </div>

            <p className='error-text'>
              {errorMessageLogin && errorMessageLogin?.split('\n').map((line: any, index:any) => (
                <span key={index} className='mb-10'>
                  {line}
                  <br />
                </span>
              ))}
            </p>
          </>}

          <div className='menu-footer'>
                <div className='menu-footer-option' onClick={()=>setTermsOfUsePopup(true)}>terms of use</div>
          </div>
        </>
        :
        <div>
          <div className='title-row'>
            <img src={padlock} className='title-icon'/>
            <h1 className='title-text'>Youre logged in!</h1>
          </div>

          <div className={'button-login margin-top'} onClick={()=>{routeTo('/home')}}>
              Home
          </div>


          <div className='menu-footer'>
                <div className='menu-footer-option' onClick={()=>setTermsOfUsePopup(true)}>terms of use</div>
          </div>
        </div>
        }

        {termsOfUsePopup &&
        <div className='terms-popup'>
            <div className='exit-row-terms'>
                <Hamburger toggled={true} toggle={()=>{setTermsOfUsePopup(false)}} color='#F7F7F8'/>
            </div>
            <TermsOfUse/>
        </div>}
    </div>
  );
};

export default LoginPage;
