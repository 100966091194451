import * as actionType from '../../actions/ActionTypes';
import { PropertyConfigurationState } from '../../model/components/PropertyConfigurationState';

const initialState: PropertyConfigurationState = {
  propertyNames: ['test',"KPKPKPK"],
  data: null,
  propertyConfiguration: {}, // Add this line
};

export const property_configuration_reducer = (state = initialState, action: any): PropertyConfigurationState => {
  switch (action.type) {
    case actionType.SET_PROPERTY_ASSESSMENT_DATA:
      return { 
        ...state, 
        data: action.payload?.state,
      };
    case actionType.SET_PROPERTY_NAMES:
      return {
        ...state,
        propertyNames: action.payload?.state,
      };
    case actionType.SET_PROPERTY_CONFIGURATION:
      return {
        ...state,
        propertyConfiguration: {
          ...state.propertyConfiguration,
          [action.payload.propertyName]: action.payload,
        },
      };
    default:
      return state;
  }
};
