import React from 'react';

const ProjectSummaryPage = () => {
  return (
    <div className='background'>
      <h1 className='margin-top'>Project Summary</h1>
      <p>This is the content of GUI Page 4.</p>
    </div>
  );
};

export default ProjectSummaryPage;
