import { GeneralState } from '../model/GeneralState';
import * as actionType from '../actions/ActionTypes';

interface Action {
  type: string;
  payload?: {
    state?: any;
  };
}

export const general_state_reducer = (state = new GeneralState(), action: any): GeneralState => {
  switch (action.type) {
    case actionType.CHANGE_HEIGHT:
      return { ...state, height: action.payload?.state };
    case actionType.CHANGE_WIDTH:
      return { ...state, width: action.payload?.state };
    case actionType.TOGGLE_OPEN_CLOSE_MENU:
      return { ...state, openCloseMenu: action.payload?.state };
    case actionType.REROUTE_APP:
      return { ...state, reroute: action.payload?.state };
    case actionType.REFRESH_COUNT:
      return { ...state, refreshCount: action.payload?.state };
    default:
      return state;
  }
};
