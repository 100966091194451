import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../assets/css/Login.css"
import "../../assets/css/404.css"
import icon from "../../assets/images/404.png"
import { useSelector } from 'react-redux';

const PageNotFound = () => {
  const navigate = useNavigate();
  const {token} = useSelector((state: any)=>state.auth)

  const routeTo = (path: string) => {
        
    if(token){
        navigate(path);
    }

  };

  return (
    <div className='background'>
 
        <h1 className='title-text-404 margin-top'>Page Not Found</h1>
        <img src={icon} className='title-icon-404'/>

        {token?
        <div className={'button-login margin-top'} onClick={()=>{routeTo('/home')}}>
            Home
        </div>
            :
        <div className={'button-login margin-top'} onClick={()=>{routeTo('/login')}}>
            Login
        </div>
        }

    </div>
  );
};

export default PageNotFound;
