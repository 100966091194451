import * as actionType from '../actions/ActionTypes';
import { SET_TOKEN, SET_REFRESH_TOKEN, SET_TOKEN_ID, IS_LOADING_LOGIN, IS_LOADING_LOGOUT, IS_LOADING_REFRESH, IS_LOADING_VALIDATE, SET_AUTH_ERROR_MESSAGE, SET_USERNAME } from '../actions/ActionTypes';
import { AuthState } from '../model/AuthState';

interface SetLoadingLoginAction {
  type: typeof IS_LOADING_LOGIN;
  payload: boolean;
}

interface SetLoadingLogoutAction {
  type: typeof IS_LOADING_LOGOUT;
  payload: boolean;
}

interface SetLoadingRefreshAction {
  type: typeof IS_LOADING_REFRESH;
  payload: boolean;
}

interface SetLoadingValidateAction {
  type: typeof IS_LOADING_VALIDATE;
  payload: boolean;
}

interface Action {
  type: string;
  payload?: {
    state?: any;
  };
}

type AuthAction =
  Action
  | SetLoadingLoginAction
  | SetLoadingLogoutAction
  | SetLoadingRefreshAction
  | SetLoadingValidateAction;

export const auth_reducer = (state = new AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.payload as string | null};
    case SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload as string | null };
    case IS_LOADING_LOGIN:
      return { ...state, isLoadingLogin: action.payload as boolean };
    case IS_LOADING_LOGOUT:
      return { ...state, isLoadingLogout: action.payload as boolean };
    case IS_LOADING_REFRESH:
      return { ...state, isLoadingRefresh: action.payload as boolean };
    case IS_LOADING_VALIDATE:
      return { ...state, isLoadingValidate: action.payload as boolean };
    case SET_AUTH_ERROR_MESSAGE:
      return { ...state, errorMessageLogin: action.payload as string | null };
    case SET_USERNAME:
      return { ...state, username: action.payload as string | null };
    case SET_TOKEN_ID:
      return { ...state, tokenId: action.payload as string | null };
    default:
      return state;
  }
};