import React, { useEffect, useState } from 'react';
import '../../assets/css/NavBar.css';
import logo from '../../assets/images/royal-gold-logo-icon.png';


{/* Navigation Bar - listens to screen size and returns the mobile or pc version (hamburger menu difference) */}
const TermsOfUse = () => {

  return (
    <div className='background-terms'>
      <div className='container-terms'>
        <h2>Terms of Service</h2>

        <p>Last Updated: August 26, 2024</p>

        <h3>1. Acceptance of Terms</h3>
        <p>By accessing or using rgldapp.com ("the App"), you agree to be bound by these Terms of Service ("ToS"). If you do not agree with any part of these terms, you are prohibited from accessing the App. This ToS is applicable only to authorized users within Royal Gold Inc. ("the Organization").</p>

        <h3>2. Access and Authentication</h3>
        <p>The App is password-protected and restricted to authorized personnel within the Organization. Unauthorized access is strictly prohibited. You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.</p>

        <h3>3. Authorized Use</h3>
        <p>The App is intended solely for the internal use of the Organization. You agree to use the App only for lawful purposes and in accordance with these ToS. Any attempt to gain unauthorized access, disrupt operations, or use the App for purposes outside of the Organization's mission is prohibited.</p>

        <h3>4. User Responsibilities</h3>
        <p>You agree to use the App in compliance with all applicable laws and regulations.
        You are responsible for safeguarding any data you access through the App and must not share, distribute, or misuse the information for purposes outside the scope of your role within the Organization.
        You shall not introduce any viruses, malware, or other harmful code into the App.</p>

        <h3>5. Data Privacy</h3>
        <p>Royal Gold Inc. may collect, store, and process certain data as necessary for the operation of the App. This data will be handled in accordance with the Organization's Data Privacy Policy. By using the App, you consent to such data collection and processing. For more detailed information on our data privacy practices, please refer to our <a style={{color:'lightblue'}} href='https://www.royalgold.com/privacy-policy'>Privacy Policy.</a></p>

        <h3>6. Intellectual Property</h3>
        <p>All content, features, and functionality of the App, including but not limited to text, graphics, logos, and software, are the exclusive property of Royal Gold Inc. and are protected by copyright, trademark, and other intellectual property laws. You may not use, copy, modify, or distribute any content from the App without express permission from the Organization.</p>

        <h3>7. Termination of Access</h3>
        <p>Royal Gold Inc. reserves the right to terminate or suspend your access to the App at any time, without prior notice, for conduct that violates these ToS or is harmful to the Organization.</p>

        <h3>8. Disclaimer of Warranties</h3>
        <p>The App is provided on an "as is" and "as available" basis. Royal Gold Inc. makes no warranties, expressed or implied, regarding the App's operation or the accuracy of the content provided.</p>

        <h3>9. Limitation of Liability</h3>
        <p>In no event shall Royal Gold Inc. be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to the use of or inability to use the App, even if the Organization has been advised of the possibility of such damages.</p>

        <h3>10. Changes to Terms</h3>
        <p>Royal Gold Inc. reserves the right to modify these ToS at any time. Changes will be effective immediately upon posting. Your continued use of the App after any changes signifies your acceptance of the modified ToS.</p>

        <h3>Royal Gold Inc.</h3>
      </div>
    </div>
  )
      
};

export default TermsOfUse;
