/*
This object holds all the state variables globally (similar to object oriented programming - but for JavaScript!). 
It keeps track of the variables and what they are in the app, which buttons have been clicked and so on. Each time a change is dispatched 
to the redux store, it forces a re-render (using variables called in the useSelector) so that everything looks and runs smoothly.
*/

class AuthState {
    token: string | null;
    refreshToken: string | null;
    tokenId: string | null;
    username: string | null;
    isLoadingLogin: boolean;
    isLoadingLogout: boolean;
    isLoadingRefresh: boolean;
    isLoadingValidate: boolean;
    errorMessageLogin: string | null;

    /*
    Initialize the variables
    */
    constructor() {
        this.token = null
        this.refreshToken = null
        this.tokenId = null
        this.username = null
        this.isLoadingLogin = false
        this.isLoadingLogout = false
        this.isLoadingRefresh = false
        this.isLoadingValidate = false
        this.errorMessageLogin = null
    }
}

export { AuthState };
