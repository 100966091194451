export const PropertyName = ({propertyNames, setPropertyName, reload}:{propertyNames:any, setPropertyName:any, reload:any}) => {
  return(
    <div className='row-left-pr'>
      <p>
        Property
      </p>
      <select className='dropdown-pr' onChange={(e)=>{setPropertyName(e.target.value)}} onClick={reload}>
        {propertyNames && propertyNames.map((name:string, id:any)=>
          <option key={id} className=''>
            {name}
          </option>
        )}
      </select>
    </div>
  )
}

export const PropertyAssessmentName = ({propertyAssessmentNames, setPropertyAssessmentName, reload}:{propertyAssessmentNames: any, setPropertyAssessmentName:any, reload: any}) => {

  return(
    <div className='row-left-pr'>
      <p>
        Property Assessment Name
      </p>
      <select className='dropdown-pr' onChange={(e)=>{propertyAssessmentNames && propertyAssessmentNames?.length > 0 && e.target.value!=undefined && setPropertyAssessmentName(e.target.value)}} onClick={reload}>
        {propertyAssessmentNames && propertyAssessmentNames?.length > 0 ? 
        propertyAssessmentNames.map((name:string, id:any)=>
          <option key={id} className=''>
            {name}
          </option>) : <option>No assessments for this property</option>
        }
      </select>
    </div>
  )

}

export const Jurisdiction = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return(
    <div className='row-left-pr'>
        <div className="column-1-pr">
            Jurisdiction
        </div>
        
        {/* Current Score */}
        <div className="column-2-pr">
          <input 
            className={`current-score-pr ${projectRankingData?.current_data?.['Jurisdiction'] !== initialProjectRankingData?.current_data?.['Jurisdiction'] ? 'highlighted-input-pr' : ''}`} 
            type="number" 
            value={projectRankingData?.current_data?.['Jurisdiction'] ?? ''}
            onChange={(e) => handleInputChange('current_data', 'Jurisdiction', parseFloat(e.target.value))}
          />
        </div>

        {/* Potential Score */}
        <div className="column-3-pr">
          <input 
            className={`current-score-pr ${projectRankingData?.potential_data?.['Jurisdiction'] !== initialProjectRankingData?.potential_data?.['Jurisdiction'] ? 'highlighted-input-pr' : ''}`} 
            type="number"
            value={projectRankingData?.potential_data?.['Jurisdiction'] ?? ''}
            onChange={(e) => handleInputChange('potential_data', 'Jurisdiction', parseFloat(e.target.value))}
          />
        </div>
        
        {/* Comments */}
        <div className="column-4-pr">
          <input 
            className={`comments-pr ${projectRankingData?.comments?.['Jurisdiction_comments'] !== initialProjectRankingData?.comments?.['Jurisdiction_comments'] ? 'highlighted-input-pr' : ''}`} 
            value={projectRankingData?.comments?.['Jurisdiction_comments'] ?? ''}
            onChange={(e) => handleInputChange('comments', 'Jurisdiction_comments', e.target.value)}
          />
        </div>

      </div>
  )
}

export const AnnualAuEqProduction = ({projectRankingData, initialProjectRankingData, handleInputChange, toggleExpand1, isExpanded1}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any, toggleExpand1: any, isExpanded1: boolean}) => {
  return(

    <div className='row-left-pr'>
        <div className="column-1-pr">
          Annual AuEq Production
        </div>
        
        {/* Current Score */}
        <div className="column-2-pr">
          <input 
            className={`current-score-pr ${projectRankingData?.current_data?.['AuEq_Annual_Production'] !== initialProjectRankingData?.current_data?.['AuEq_Annual_Production'] ? 'highlighted-input-pr' : ''}`} 
            type="number" 
            value={projectRankingData?.current_data?.['AuEq_Annual_Production'] ?? ''}
            onChange={(e) => handleInputChange('current_data', 'AuEq_Annual_Production', parseFloat(e.target.value))}
          />
        </div>

        {/* Potential Score */}
        <div className="column-3-pr">
          <input 
            className={`current-score-pr ${projectRankingData?.potential_data?.['AuEq_Annual_Production'] !== initialProjectRankingData?.potential_data?.['AuEq_Annual_Production'] ? 'highlighted-input-pr' : ''}`} 
            type="number" 
            value={projectRankingData?.potential_data?.['AuEq_Annual_Production'] ?? ''}
            onChange={(e) => handleInputChange('potential_data', 'AuEq_Annual_Production', parseFloat(e.target.value))}
            />
        </div>

        {/* Comments */}
        <div className="column-4-pr relative-col4-pr">
          <input 
            className={`comments-pr pr-45 ${projectRankingData?.comments?.['Annualaueq_comments'] !== initialProjectRankingData?.comments?.['Annualaueq_comments'] ? 'highlighted-input-pr' : ''}`} 
            value={projectRankingData?.comments?.['Annualaueq_comments'] ?? ''}
            onChange={(e) => handleInputChange('comments', 'Annualaueq_comments', e.target.value)}
            />
            <div className={"absolute-col4-pr"} onClick={toggleExpand1}>
              {isExpanded1 ? 'Hide' : 'Calc'}
            </div>
        </div>

    </div>

  )
}

export const GrossMargin = ({projectRankingData, initialProjectRankingData, handleInputChange, toggleExpand2, isExpanded2}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any, toggleExpand2: any, isExpanded2: boolean}) => {
  return (
      <div className='row-left-pr'>
        <div className="column-1-pr">Gross Margin</div>
        
        {/* Current Score */}
        <div className="column-2-pr">
          <input 
            className={`current-score-pr ${projectRankingData?.current_data?.['Gross_Margin'] !== initialProjectRankingData?.current_data?.['Gross_Margin'] ? 'highlighted-input-pr' : ''}`} 
            type="number" 
            value={projectRankingData?.current_data?.['Gross_Margin'] ?? ''}
            onChange={(e) => handleInputChange('current_data', 'Gross_Margin', parseFloat(e.target.value))}
          />
        </div>

        {/* Potential Score */}
        <div className="column-3-pr">
          <input 
            className={`current-score-pr ${projectRankingData?.potential_data?.['Gross_Margin'] !== initialProjectRankingData?.potential_data?.['Gross_Margin'] ? 'highlighted-input-pr' : ''}`} 
            type="number" 
            value={projectRankingData?.potential_data?.['Gross_Margin'] ?? ''}
            onChange={(e) => handleInputChange('potential_data', 'Gross_Margin', parseFloat(e.target.value))}
          />
        </div>
        
        {/* Comments */}
        <div className="column-4-pr relative-col4-pr">
          <input 
            className={`comments-pr pr-45 ${projectRankingData?.comments?.['Grossmargin_comments'] !== initialProjectRankingData?.comments?.['Grossmargin_comments'] ? 'highlighted-input-pr' : ''}`} 
            value={projectRankingData?.comments?.['Grossmargin_comments'] ?? ''}
            onChange={(e) => handleInputChange('comments', 'Grossmargin_comments', e.target.value)}
          />
          <div className={"absolute-col4-pr"} onClick={toggleExpand2}>
            {isExpanded2 ? 'Hide' : 'Calc'}
          </div>
        </div>
      </div>
  );
};

export const CompanyFinancialStrength = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Company Financial Strength</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Company_Financial_Strength'] !== initialProjectRankingData?.current_data?.['Company_Financial_Strength'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Company_Financial_Strength'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Company_Financial_Strength', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Company_Financial_Strength'] !== initialProjectRankingData?.potential_data?.['Company_Financial_Strength'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Company_Financial_Strength'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Company_Financial_Strength', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Companyfinstrength_comments'] !== initialProjectRankingData?.comments?.['Companyfinstrength_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Companyfinstrength_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Companyfinstrength_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

// Example usage for another field
export const MetalType = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Metal Type</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Metal_Type'] !== initialProjectRankingData?.current_data?.['Metal_Type'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Metal_Type'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Metal_Type', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Metal_Type'] !== initialProjectRankingData?.potential_data?.['Metal_Type'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Metal_Type'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Metal_Type', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Metaltype_comments'] !== initialProjectRankingData?.comments?.['Metaltype_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Metaltype_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Metaltype_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const CompanyTeam = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Company/Team</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Company_Team'] !== initialProjectRankingData?.current_data?.['Company_Team'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Company_Team'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Company_Team', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Company_Team'] !== initialProjectRankingData?.potential_data?.['Company_Team'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Company_Team'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Company_Team', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Companyteam_comments'] !== initialProjectRankingData?.comments?.['Companyteam_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Companyteam_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Companyteam_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const Stage = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Stage</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Stage'] !== initialProjectRankingData?.current_data?.['Stage'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Stage'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Stage', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Stage'] !== initialProjectRankingData?.potential_data?.['Stage'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Stage'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Stage', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Stage_comments'] !== initialProjectRankingData?.comments?.['Stage_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Stage_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Stage_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const Permitting = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Permitting</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Permitting'] !== initialProjectRankingData?.current_data?.['Permitting'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Permitting'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Permitting', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Permitting'] !== initialProjectRankingData?.potential_data?.['Permitting'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Permitting'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Permitting', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Permitting_comments'] !== initialProjectRankingData?.comments?.['Permitting_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Permitting_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Permitting_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const ESGWater = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">ESG - Water</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['ESG_Water'] !== initialProjectRankingData?.current_data?.['ESG_Water'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['ESG_Water'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'ESG_Water', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['ESG_Water'] !== initialProjectRankingData?.potential_data?.['ESG_Water'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['ESG_Water'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'ESG_Water', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['ESG_Water_Comments'] !== initialProjectRankingData?.comments?.['ESG_Water_Comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['ESG_Water_Comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'ESG_Water_Comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const ESGCommunity = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">ESG - Community</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['ESG_Community'] !== initialProjectRankingData?.current_data?.['ESG_Community'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['ESG_Community'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'ESG_Community', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['ESG_Community'] !== initialProjectRankingData?.potential_data?.['ESG_Community'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['ESG_Community'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'ESG_Community', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['ESG_Community_Comments'] !== initialProjectRankingData?.comments?.['ESG_Community_Comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['ESG_Community_Comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'ESG_Community_Comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const ESGGHG = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">ESG - GHG</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['ESG_GHG'] !== initialProjectRankingData?.current_data?.['ESG_GHG'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['ESG_GHG'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'ESG_GHG', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['ESG_GHG'] !== initialProjectRankingData?.potential_data?.['ESG_GHG'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['ESG_GHG'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'ESG_GHG', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['ESG_GHG_Comments'] !== initialProjectRankingData?.comments?.['ESG_GHG_Comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['ESG_GHG_Comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'ESG_GHG_Comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const Reserves = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Reserves</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Reserves'] !== initialProjectRankingData?.current_data?.['Reserves'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Reserves'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Reserves', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Reserves'] !== initialProjectRankingData?.potential_data?.['Reserves'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Reserves'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Reserves', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Reserves_comments'] !== initialProjectRankingData?.comments?.['Reserves_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Reserves_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Reserves_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const ResourcesExploration = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Resources/Exploration</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Resource_Conversion_Exploration'] !== initialProjectRankingData?.current_data?.['Resource_Conversion_Exploration'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Resource_Conversion_Exploration'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Resource_Conversion_Exploration', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Resource_Conversion_Exploration'] !== initialProjectRankingData?.potential_data?.['Resource_Conversion_Exploration'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Resource_Conversion_Exploration'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Resource_Conversion_Exploration', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Upside_comments'] !== initialProjectRankingData?.comments?.['Upside_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Upside_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Upside_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const DepositType = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Deposit Type</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Deposit_Type'] !== initialProjectRankingData?.current_data?.['Deposit_Type'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Deposit_Type'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Deposit_Type', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Deposit_Type'] !== initialProjectRankingData?.potential_data?.['Deposit_Type'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Deposit_Type'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Deposit_Type', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Deposit_comments'] !== initialProjectRankingData?.comments?.['Deposit_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Deposit_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Deposit_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const MiningComplexity = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Mining Complexity</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Mine_Complexity'] !== initialProjectRankingData?.current_data?.['Mine_Complexity'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Mine_Complexity'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Mine_Complexity', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Mine_Complexity'] !== initialProjectRankingData?.potential_data?.['Mine_Complexity'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Mine_Complexity'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Mine_Complexity', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Miningcomplexity_comments'] !== initialProjectRankingData?.comments?.['Miningcomplexity_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Miningcomplexity_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Miningcomplexity_comments', e.target.value)}
        />
      </div>
    </div>
  );
};


export const ProcessingComplexity = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Processing Complexity</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Process_Complexity'] !== initialProjectRankingData?.current_data?.['Process_Complexity'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Process_Complexity'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Process_Complexity', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Process_Complexity'] !== initialProjectRankingData?.potential_data?.['Process_Complexity'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Process_Complexity'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Process_Complexity', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Processingcomplexity_comments'] !== initialProjectRankingData?.comments?.['Processingcomplexity_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Processingcomplexity_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Processingcomplexity_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const Infrastructure = ({projectRankingData, initialProjectRankingData, handleInputChange}: {projectRankingData: any, initialProjectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Infrastructure</div>
      
      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.current_data?.['Infrastructure'] !== initialProjectRankingData?.current_data?.['Infrastructure'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.current_data?.['Infrastructure'] ?? ''}
          onChange={(e) => handleInputChange('current_data', 'Infrastructure', parseFloat(e.target.value))}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className={`current-score-pr ${projectRankingData?.potential_data?.['Infrastructure'] !== initialProjectRankingData?.potential_data?.['Infrastructure'] ? 'highlighted-input-pr' : ''}`} 
          type="number" 
          value={projectRankingData?.potential_data?.['Infrastructure'] ?? ''}
          onChange={(e) => handleInputChange('potential_data', 'Infrastructure', parseFloat(e.target.value))}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['Infrastructure_comments'] !== initialProjectRankingData?.comments?.['Infrastructure_comments'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['Infrastructure_comments'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'Infrastructure_comments', e.target.value)}
        />
      </div>
    </div>
  );
};

export const ResourceSizeAuEq = ({projectRankingData, handleInputChange}: {projectRankingData: any, handleInputChange: any}) => {
  return (
    <div className='row-left-pr'>
      <div className="column-1-pr">Resource Size Au Eq (Moz)</div>

      {/* Current Score */}
      <div className="column-2-pr">
        <input 
          className='current-score-pr br-none' 
          type="number" 
          disabled={true}
          value={projectRankingData?.['Resource_Size_AuEq'] ?? ''}
        />
      </div>

      {/* Potential Score */}
      <div className="column-3-pr">
        <input 
          className='projected-score-pr br-none' 
          type="number" 
          disabled={true}
          value={projectRankingData?.['Projected_Resource_Size_AuEq'] ?? ''}
        />
      </div>

      {/* Comments */}
      <div className="column-4-pr">
        <input 
          className={`comments-pr ${projectRankingData?.comments?.['ResourcePlotSize'] !== projectRankingData?.comments?.['ResourcePlotSize'] ? 'highlighted-input-pr' : ''}`} 
          value={projectRankingData?.comments?.['ResourcePlotSize'] ?? ''}
          onChange={(e) => handleInputChange('comments', 'ResourcePlotSize', e.target.value)}
        />
      </div>
    </div>
  );
};

