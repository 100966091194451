import React from 'react';
import gui2 from '../../assets/images/assessment-config.png'
import gui3 from '../../assets/images/economic-assessment.png'
import gui5 from '../../assets/images/project-ranking.png'
import gui4 from '../../assets/images/project-summary.png'
import gui1 from '../../assets/images/property-config.png'
import "../../assets/css/HomeScreen.css"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HomeScreen = () => {

  const navigate = useNavigate()
  const {token} = useSelector((state: any)=>state.auth)

  const routeTo = (path: string) => {
    navigate(path);
  };  

  return (
    token ?
    <div className='background'>
      <div className='margin-top-home'/>
      <div className='button-text-link-row  fade-in'>
        <div className='button-text-link-column'>
          <img src={gui1} width={75} className='button-icon-link' onClick={()=>{routeTo('/property-configuration')}}/>
          <p className='button-text-link'>
            Property Configuration
          </p>
        </div>
        <div className='button-text-link-column'>
          <img src={gui2} width={70} className='button-icon-link' onClick={()=>{routeTo('/assessment-configuration')}}/>
          <p className='button-text-link'>
            Assessment Configuration
          </p>
        </div>
      </div>

      <div className='button-text-link-row fade-in'>
        <div className='button-text-link-column'>
          <img src={gui3} width={65} className='button-icon-link' onClick={()=>{routeTo('/economic-assessment')}}/>
          <p className='button-text-link'>
            Economic Assessment
          </p>
        </div>
        <div className='button-text-link-column'>
          <img src={gui4}  width={85} className='button-icon-link' onClick={()=>{routeTo('/project-summary')}}/>
          <p className='button-text-link'>
            Project Summary
          </p>
        </div>
      </div>

      <div className='button-text-link-row fade-in'>
        <div className='button-text-link-column'>
          <img src={gui5} width={70} className='button-icon-link' onClick={()=>{routeTo('/project-ranking')}}/>
          <p className='button-text-link'>
            Project Ranking
          </p>
        </div>
        <div className='blank-column'>
          
        </div>
      </div>

    </div>
    :
    <div className='background'/>
  );
};

export default HomeScreen;
