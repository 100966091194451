
import { ProjectRankingData } from '../state/redux/model/components/ProjectRankingState';
import jsPDF from 'jspdf';
import autoTable, { CellInput, UserOptions } from 'jspdf-autotable';
import royalGoldLogo from '../assets/images/royal-gold-logo-header.png'; // Import image
import * as api from '../state/api/api';

function sanitizeCellValue(value: string | number | null | undefined): string | number {
    return value !== null && value !== undefined ? value : '';
}

export function createPDF(projectRankingData: ProjectRankingData): void {
    
    try {
        if (projectRankingData) {
            const {
                potential_data,
                current_data,
                comments
            } = projectRankingData;

            const doc = new jsPDF();

            // Title and Logo on the same line
            const imgWidth = 35; // desired width in the PDF
            const imgHeight = (70 / 284) * imgWidth; // maintaining the aspect ratio

            const title = "Project Qualitative Ranking";
            const pageWidth = doc.internal.pageSize.getWidth(); // Get the width of the page
            const titleXPosition = (pageWidth / 2) - (doc.getTextWidth(title) / 2); // Center the title
            const titleYPosition = 15;

            // Encode the logo image and place it next to the title
            const img = new Image();
            img.src = royalGoldLogo;
            img.onload = function () {
                // Add the logo to the document, position it to the left of the title
                //doc.addImage(img, 'PNG', 10, 5, imgWidth, imgHeight);  // Adjust size and position
                doc.setFontSize(14);
                doc.text(title, titleXPosition, titleYPosition);  // Place title next to image

                // Initialize commercial and technical score totals
                let commercialCurrentTotal = 0;
                let commercialPotentialTotal = 0;
                let technicalCurrentTotal = 0;
                let technicalPotentialTotal = 0;

                commercialCurrentTotal += current_data?.Jurisdiction || 0
                commercialPotentialTotal += potential_data?.Jurisdiction || 0;
                
                commercialCurrentTotal += current_data?.AuEq_Annual_Production || 0
                commercialPotentialTotal += potential_data?.AuEq_Annual_Production || 0;
                
                commercialCurrentTotal += current_data?.Metal_Type || 0;
                commercialPotentialTotal += potential_data?.Metal_Type || 0;

                commercialCurrentTotal += (current_data?.Gross_Margin || 0) * 2; // 200% weight
                commercialPotentialTotal += (potential_data?.Gross_Margin || 0) * 2;

                commercialCurrentTotal += (current_data?.Company_Financial_Strength || 0) * 2; // 200% weight
                commercialPotentialTotal += (potential_data?.Company_Financial_Strength || 0) * 2;

                commercialCurrentTotal += (current_data?.Company_Team || 0); // 100% weight
                commercialPotentialTotal += (potential_data?.Company_Team || 0);

                // Technical Scores
                technicalCurrentTotal += (current_data?.Stage || 0) * 0.5; // 50% weight
                technicalPotentialTotal += (potential_data?.Stage || 0) * 0.5;

                technicalCurrentTotal += (current_data?.Permitting || 0) * 0.5; // 50% weight
                technicalPotentialTotal += (potential_data?.Permitting || 0) * 0.5;

                technicalCurrentTotal += (current_data?.ESG_Water || 0) * 0.5; // 50% weight
                technicalPotentialTotal += (potential_data?.ESG_Water || 0) * 0.5;

                technicalCurrentTotal += (current_data?.ESG_Community || 0) * 0.25; // 25% weight
                technicalPotentialTotal += (potential_data?.ESG_Community || 0) * 0.25;

                technicalCurrentTotal += (current_data?.ESG_GHG || 0) * 0.25; // 25% weight
                technicalPotentialTotal += (potential_data?.ESG_GHG || 0) * 0.25;

                technicalCurrentTotal += current_data?.Reserves || 0;
                technicalPotentialTotal += potential_data?.Reserves || 0;

                technicalCurrentTotal += current_data?.Resource_Conversion_Exploration || 0;
                technicalPotentialTotal += potential_data?.Resource_Conversion_Exploration || 0;

                technicalCurrentTotal += current_data?.Deposit_Type || 0;
                technicalPotentialTotal += potential_data?.Deposit_Type || 0;

                technicalCurrentTotal += current_data?.Mine_Complexity || 0;
                technicalPotentialTotal += potential_data?.Mine_Complexity || 0;

                technicalCurrentTotal += current_data?.Process_Complexity || 0;
                technicalPotentialTotal += potential_data?.Process_Complexity || 0;

                technicalCurrentTotal += current_data?.Infrastructure || 0;
                technicalPotentialTotal += potential_data?.Infrastructure || 0;                

                // Calculate Total Score and update row
                const totalCurrentScore = commercialCurrentTotal + technicalCurrentTotal;
                const totalPotentialScore = commercialPotentialTotal + technicalPotentialTotal;
                
                // Table Columns and Rows
                const rows: CellInput[][] = [
                    ["Jurisdiction", "100%", sanitizeCellValue(current_data?.Jurisdiction), sanitizeCellValue(potential_data?.Jurisdiction), sanitizeCellValue(comments?.Jurisdiction_comments)],
                    ["Annual Production (AuEQ)", "100%", sanitizeCellValue(current_data?.AuEq_Annual_Production), sanitizeCellValue(potential_data?.AuEq_Annual_Production), sanitizeCellValue(comments?.Annualaueq_comments)],
                    ["Metal Type", "100%", sanitizeCellValue(current_data?.Metal_Type), sanitizeCellValue(potential_data?.Metal_Type), sanitizeCellValue(comments?.Metaltype_comments)],
                    ["Gross Margin (%)", "200%", sanitizeCellValue(current_data?.Gross_Margin), sanitizeCellValue(potential_data?.Gross_Margin), sanitizeCellValue(comments?.Grossmargin_comments)],
                    ["Company Financial Strength", "200%", sanitizeCellValue(current_data?.Company_Financial_Strength), sanitizeCellValue(potential_data?.Company_Financial_Strength), sanitizeCellValue(comments?.Companyfinstrength_comments)],
                    ["Company / Team", "100%", sanitizeCellValue(current_data?.Company_Team), sanitizeCellValue(potential_data?.Company_Team), sanitizeCellValue(comments?.Companyteam_comments)],
                    [{ content: "Total Commercial", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: "", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: commercialCurrentTotal.toString(), colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: commercialPotentialTotal.toString(), colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: "", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    ], // Total Commercial row (bold)
                    [{ content: "Technical Scores", colSpan: 1, styles: { halign: 'center', fillColor: [150, 150, 150], textColor: [0, 0, 0] } } as CellInput,
                    { content: "Weight", colSpan: 1, styles: { halign: 'center', fillColor: [150, 150, 150], textColor: [0, 0, 0] } } as CellInput,
                    { content: "Current Scores", colSpan: 1, styles: { halign: 'center', fillColor: [150, 150, 150], textColor: [0, 0, 0] } } as CellInput,
                    { content: "Potential Scores", colSpan: 1, styles: { halign: 'center', fillColor: [150, 150, 150], textColor: [0, 0, 0] } } as CellInput,
                    { content: "Comments", colSpan: 1, styles: { halign: 'center', fillColor: [150, 150, 150], textColor: [0, 0, 0] } } as CellInput,
                    ],  // Technical scores header with dark background
                    ["Project Stage", "50%", sanitizeCellValue(current_data?.Stage), sanitizeCellValue(potential_data?.Stage), sanitizeCellValue(comments?.Stage_comments)],
                    ["Permitting Status", "50%", sanitizeCellValue(current_data?.Permitting), sanitizeCellValue(potential_data?.Permitting), sanitizeCellValue(comments?.Permitting_comments)],
                    ["ESG (Water)", "50%", sanitizeCellValue(current_data?.ESG_Water), sanitizeCellValue(potential_data?.ESG_Water), sanitizeCellValue(comments?.ESG_Water_Comments)],
                    ["ESG (Community)", "25%", sanitizeCellValue(current_data?.ESG_Community), sanitizeCellValue(potential_data?.ESG_Community), sanitizeCellValue(comments?.ESG_Community_Comments)],
                    ["ESG (GHG Emissions)", "25%", sanitizeCellValue(current_data?.ESG_GHG), sanitizeCellValue(potential_data?.ESG_GHG), sanitizeCellValue(comments?.ESG_GHG_Comments)],
                    ["Reserves / Mineable Tonnage", "100%", sanitizeCellValue(current_data?.Reserves), sanitizeCellValue(potential_data?.Reserves), sanitizeCellValue(comments?.Reserves_comments)],
                    ["Resource Conversion Exploration Upside", "100%", sanitizeCellValue(current_data?.Resource_Conversion_Exploration), sanitizeCellValue(potential_data?.Resource_Conversion_Exploration), sanitizeCellValue(comments?.Upside_comments)],
                    ["Deposit Shape / Complexity", "100%", sanitizeCellValue(current_data?.Deposit_Type), sanitizeCellValue(potential_data?.Deposit_Type), sanitizeCellValue(comments?.Deposit_comments)],
                    ["Mining Complexity", "100%", sanitizeCellValue(current_data?.Mine_Complexity), sanitizeCellValue(potential_data?.Mine_Complexity), sanitizeCellValue(comments?.Miningcomplexity_comments)],
                    ["Process Complexity", "100%", sanitizeCellValue(current_data?.Process_Complexity), sanitizeCellValue(potential_data?.Process_Complexity), sanitizeCellValue(comments?.Processingcomplexity_comments)],
                    ["Infrastructure", "100%", sanitizeCellValue(current_data?.Infrastructure), sanitizeCellValue(potential_data?.Infrastructure), sanitizeCellValue(comments?.Infrastructure_comments)],
                    [{ content: "Total Technical", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: "", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: technicalCurrentTotal.toString(), colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: technicalPotentialTotal.toString(), colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: "", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    ], // Total Technical row (bold)
                    [{ content: "Total Score", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: "", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' }} as CellInput,
                    { content: totalCurrentScore.toString(), colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: totalPotentialScore.toString(), colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    { content: "", colSpan: 1, styles: { halign: 'center', fontStyle: 'bold' } } as CellInput,
                    ] // Total Technical row (bold)
                ];

                // Generate the table using autoTable
                autoTable(doc, {
                    head: [['Commercial Scores', 'Weight', 'Current Score (1-10)', 'Potential Score (1-10)', 'Comments']],
                    body: rows,
                    startY: titleYPosition + 5, 
                    styles: {
                        fontSize: 8,
                        halign: 'center',
                        valign: 'middle',
                        cellPadding: [0, 2, 0, 2], // Smaller padding for smaller rows
                        overflow: 'linebreak',
                        lineColor: [0, 0, 0], // Black borders
                        lineWidth: 0.5,        // Border thickness
                        minCellHeight: 6,      // Smaller row height
                    },
                    headStyles: {
                        fillColor: [150, 150, 150],  // Lighter gray background
                        textColor: [0, 0, 0],     // Dark text color (not bold)
                        fontStyle: 'normal',         // Not bold
                        cellPadding: [0, 2, 0, 2],
                    },
                    alternateRowStyles: {
                        fillColor: [242, 242, 242],  // Light gray for alternate rows
                    },
                    columnStyles: {
                        0: { cellWidth: 45, halign: 'left' },  // Left align for the first column
                        1: { cellWidth: 17 },  
                        2: { cellWidth: 19 },  
                        3: { cellWidth: 19 },  
                        4: { cellWidth: 90, halign: 'left' },  // Left align for the last column (comments)
                    },
                });

                const finalY = (doc as any).previousAutoTable.finalY || 30;
                // Final note
                doc.setFontSize(10);
                doc.text("Note: Possible score reflects possible score at time of initial investment", 10, (doc as any).previousAutoTable.finalY + 10);

                // Save the PDF
                doc.save('project_qualitative_ranking.pdf');
                console.log("Successfully saved PDF:");
            }
        }
    } catch (e) {
        console.log("Error Printing PDF:" + e);
    }
}
  

  // This function pulls the pdf from the backend at the static folder. token required
  export const handleDownloadScoringGuidelines = async () => {
    try {
      // Fetch the PDF from the backend
      const response = await api.postRequest('/api/files/scoring-guidelines', {}, true);

      if (response) {
        // Create a Blob from the response data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  
        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        // On desktop, open the PDF in a new tab
        window.open(pdfUrl, '_blank');
        
      }
    } catch (error) {
      console.log('Failed to fetch the PDF:', error);
    }
  };
  