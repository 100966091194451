import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../assets/css/NavBar.css'; // We'll define the styles in a moment
import logo from '../../assets/images/royal-gold-logo-icon.png';
import '../../assets/css/Logo.css';  
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import home from '../../assets/images/home-white.png';
import loginIcon from '../../assets/images/login.png';
import logoutIcon from '../../assets/images/logout-red.png';
import { logoutUser, logoutUserWithNav } from '../../state/redux/actions/AuthActions';


  {/* PC Navigation Bar */}
  const PCVersion = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.auth)
    const [areYouSurePopup, setAreYouSurePopup] = useState(false)

    const routeTo = (path: string) => {
        if(auth?.token){
            navigate(path);
        }
    };


    const logout = async () => {
       
        setAreYouSurePopup(false)
        await logoutUserWithNav(dispatch, navigate)

    };

    function handleBackgroundClick(event: any) {
        // Check if the click happened on the background and not on the container
        if (event.target === event.currentTarget) {
            // Close the popup
            setAreYouSurePopup(false)
        }
    }


    return (
      <div className="navbar">
        <div className="nav-logo">
          <img src={logo} className="App-logo-no-spin" alt="logo" onClick={auth?.token != null ? ()=>{routeTo(`/home`)} : ()=>{routeTo(`/login`)}}/>
          <p className='nav-text'>
            Royal Gold Utilities
          </p>
        </div>
        {auth?.token && 
        <div className='nav-menu'>
                
            <div onClick={()=>{routeTo(`/home`)}} className='pc-menu-option'>
                <img src={home} className='icon-button'/>
                Home
            </div>
            
            {auth?.token != null ? 
            <div onClick={()=>{setAreYouSurePopup(true)}} className='pc-menu-option-red'>
                <img src={logoutIcon} className='icon-button'/>
                Log Out
            </div>
            : 
            <div onClick={()=>{routeTo(`/login`)}} className='pc-menu-option'>
                <img src={loginIcon} className='icon-button'/>
                Log In
            </div>}

            {areYouSurePopup &&
            <div className='logout-popup-background' onClick={(e)=>{handleBackgroundClick(e)}}>
                <div className='logout-popup-container'>
                    <h2>Are you sure?</h2>
                    <div onClick={()=>{setAreYouSurePopup(false)}} className='menu-option button-profile mt-10'>
                        Cancel
                    </div>
                    <div onClick={()=>{logout()}} className='menu-option button-profile-red mt-20'>
                        Log Out
                    </div>
                </div>
            </div>}
            
        </div>}
      </div>
    );
  };

export default PCVersion;