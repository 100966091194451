import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeight, changeWidth, reRouteApp } from '../../state/redux/actions/GeneralStateActions';
import '../../assets/css/NavBar.css';
import MobileVersion from './NavMobile';
import PCVersion from './NavPC';

{/* Navigation Bar - listens to screen size and returns the mobile or pc version (hamburger menu difference) */}
const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width, openCloseMenu, reroute} = useSelector((state: any) => state.general)
  const location = useLocation();

  // force a re-route if our variable changes
  useEffect(()=>{
      const route = reroute
      if(route !== null){
        dispatch(reRouteApp(null)) // reset the route variable
        navigate(route) // move to the new page
      }
  },[reroute])  

  // force a re-route to the login screen if the token is not present
  useEffect(() => {
    const token = localStorage.getItem('token');

    // Check if the user is not on the login page and the token is null
    if (!token && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      dispatch(changeWidth(width));
      dispatch(changeHeight(height));
    };

    // Initial call to set dimensions when the function is first run
    handleResize();

    // Add event listener for resize events
    window.addEventListener('resize', handleResize);

    // Return a cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  {/* SCEEN WIDTH */}
  return width < 768 ? (
    <MobileVersion />
  ) : (
    <PCVersion />
  );
      
};

export default NavBar;
