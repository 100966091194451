import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ProjectRankingData } from '../../../state/redux/model/components/ProjectRankingState';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/redux/model/RootState';
import '../../../assets/css/ProjectRankingPlot.css'; // Import CSS file for custom styling


Chart.register(...registerables, ChartDataLabels);

const ProjectRankingPlot = () => {
  const { projectRankingData } = useSelector((state: RootState) => state.project_ranking);
  const resourceAuEQ = projectRankingData?.comments?.ResourcePlotSize || 1; // Default to 1 to avoid zero division

  if (!projectRankingData?.potential_data || !projectRankingData?.current_data) {
    return <div>Loading...</div>;
  }

  // Calculating commercial and technical scores
  const [commercialCurrentTotal, commercialPotentialTotal] = calculateCommercialScores(projectRankingData);
  const [technicalCurrentTotal, technicalPotentialTotal] = calculateTechnicalScores(projectRankingData);

  // Mount Milligan and Phoenix constants
  const mountMilligan = { tech: 60, comm: 62, size: 2000 };
  const phoenix = { tech: 48, comm: 44, size: (3.3 / 14) * mountMilligan.size };

  // Scatter Plot Data
  const scatterData = getScatterData({
    mountMilligan, 
    phoenix, 
    technicalCurrentTotal, 
    commercialCurrentTotal, 
    technicalPotentialTotal, 
    commercialPotentialTotal, 
    resourceAuEQ
    });

  // Scatter Plot Options
  const scatterOptions: ChartOptions<'scatter'> = {
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 20,
        max: 80,
        title: { display: true, text: 'Technical Score',color: '#000000', font:{size: 17} },
        grid: { color: 'grey', lineWidth: 2 },
        ticks: {
            color: 'black',  // changes x-axis labels to black
          },
      },
      y: {
        min: 20,
        max: 80,
        title: { display: true, text: 'Commercial Score',color: '#000000', font:{size: 17} },
        grid: { color: 'grey', lineWidth: 2 },
        ticks: {
            color: 'black',  // changes y-axis labels to black
          },
      },
    },
    plugins: {
        title: {
            display: true,
            text: 'Project Ranking',
            font: { size: 24 }, // Customize the title font size if needed
            padding: { top: 20, bottom: 10 }, // Add padding for spacing
            color: 'black'    
        },
        legend: { display: false },
        tooltip: { enabled: false, },
        datalabels: {
            align: 'top',
            color: 'black',
            formatter: (value: any) => value.label,
        },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="chart-container">
      <Scatter data={scatterData} options={scatterOptions} />
    </div>
  );
};

// Helper functions to calculate scores
const calculateCommercialScores = (data: any) => {
  let currentTotal = 0;
  let potentialTotal = 0;

  currentTotal += data.current_data.Jurisdiction || 0;
  potentialTotal += data.potential_data.Jurisdiction || 0;

  currentTotal += data.current_data.AuEq_Annual_Production || 0;
  potentialTotal += data.potential_data.AuEq_Annual_Production || 0;

  currentTotal += data.current_data.Metal_Type || 0;
  potentialTotal += data.potential_data.Metal_Type || 0;

  currentTotal += (data.current_data.Gross_Margin || 0) * 2;
  potentialTotal += (data.potential_data.Gross_Margin || 0) * 2;

  currentTotal += (data.current_data.Company_Financial_Strength || 0) * 2;
  potentialTotal += (data.potential_data.Company_Financial_Strength || 0) * 2;

  currentTotal += data.current_data.Company_Team || 0;
  potentialTotal += data.potential_data.Company_Team || 0;

  return [currentTotal, potentialTotal];
};

const calculateTechnicalScores = (data: any) => {
  let currentTotal = 0;
  let potentialTotal = 0;

  currentTotal += (data.current_data.Stage || 0) * 0.5;
  potentialTotal += (data.potential_data.Stage || 0) * 0.5;

  currentTotal += (data.current_data.Permitting || 0) * 0.5;
  potentialTotal += (data.potential_data.Permitting || 0) * 0.5;

  currentTotal += (data.current_data.ESG_Water || 0) * 0.5;
  potentialTotal += (data.potential_data.ESG_Water || 0) * 0.5;

  currentTotal += (data.current_data.ESG_Community || 0) * 0.25;
  potentialTotal += (data.potential_data.ESG_Community || 0) * 0.25;

  currentTotal += (data.current_data.ESG_GHG || 0) * 0.25;
  potentialTotal += (data.potential_data.ESG_GHG || 0) * 0.25;

  currentTotal += data.current_data.Reserves || 0;
  potentialTotal += data.potential_data.Reserves || 0;

  currentTotal += data.current_data.Resource_Conversion_Exploration || 0;
  potentialTotal += data.potential_data.Resource_Conversion_Exploration || 0;

  currentTotal += data.current_data.Deposit_Type || 0;
  potentialTotal += data.potential_data.Deposit_Type || 0;

  currentTotal += data.current_data.Mine_Complexity || 0;
  potentialTotal += data.potential_data.Mine_Complexity || 0;

  currentTotal += data.current_data.Process_Complexity || 0;
  potentialTotal += data.potential_data.Process_Complexity || 0;

  currentTotal += data.current_data.Infrastructure || 0;
  potentialTotal += data.potential_data.Infrastructure || 0;

  return [currentTotal, potentialTotal];
};

// Helper function to create scatter plot data
const getScatterData = ({mountMilligan, phoenix, technicalCurrentTotal, commercialCurrentTotal, technicalPotentialTotal, commercialPotentialTotal, resourceAuEQ}:{mountMilligan:any, phoenix:any, technicalCurrentTotal:any, commercialCurrentTotal:any, technicalPotentialTotal:any, commercialPotentialTotal:any, resourceAuEQ:any}) => ({
  datasets: [
    {
      label: 'Current Property',
      data: [{ x: technicalCurrentTotal, y: commercialCurrentTotal, label: 'Current Property' }],
      backgroundColor: 'purple',
      pointRadius: Math.min(Math.max((2000 * (resourceAuEQ / 14)) / 100, 14), 42),
      datalabels: {
        labels: {
          title: {
            font: { size: 18, },
            align: 140,
       
          }
        }
      }
    },
    {
      label: 'Potential Property',
      data: [{ x: technicalPotentialTotal, y: commercialPotentialTotal, label: 'Potential Property' }],
      backgroundColor: 'purple',
      pointRadius: Math.min(Math.max((2000 * (resourceAuEQ / 14)) / 100, 14), 42),
      datalabels: {
        font: { size: 18,  },
        align: -45,

      }
    },
    {
      label: 'Mount Milligan',
      data: [{ x: mountMilligan.tech, y: mountMilligan.comm, label: 'Mount Milligan' }],
      backgroundColor: 'lightcoral',
      pointRadius: 40,
      datalabels: {
        labels: {
          title: {
            font: { size: 18 },
          }
        }
      }
    },
    {
      label: 'Phoenix',
      data: [{ x: phoenix.tech, y: phoenix.comm, label: 'Phoenix' }],
      backgroundColor: 'red',
      pointRadius: 20,
      datalabels: {
        labels: {
          title: {
            font: { size: 18 },
          }
        }
      }

    },
    {
      label: 'Green Line',
      data: greenLine.map((point) => ({ ...point, label: '' })), // Provide a dummy label
      borderColor: 'green',
      borderWidth: 4,
      showLine: true,
      fill: false,
      pointRadius: 0,
      tension: 0.4,
    },
    {
      label: 'Red Line',
      data: redLine.map((point) => ({ ...point, label: '' })), // Provide a dummy label
      borderColor: 'red',
      borderWidth: 4,
      showLine: true,
      fill: false,
      pointRadius: 0,
      tension: 0.4,
    },
  ],
});

const redLine = [
    {"x": 40.0, "y": 80.0},
    {"x": 40.14, "y": 76.70},
    {"x": 40.55, "y": 73.42},
    {"x": 41.22, "y": 70.18},
    {"x": 42.17, "y": 67.01},
    {"x": 43.37, "y": 63.93},
    {"x": 44.82, "y": 60.96},
    {"x": 46.51, "y": 58.12},
    {"x": 48.43, "y": 55.43},
    {"x": 50.57, "y": 52.91},
    {"x": 52.91, "y": 50.57},
    {"x": 55.43, "y": 48.43},
    {"x": 58.12, "y": 46.51},
    {"x": 60.96, "y": 44.82},
    {"x": 63.93, "y": 43.37},
    {"x": 67.01, "y": 42.17},
    {"x": 70.18, "y": 41.22},
    {"x": 73.42, "y": 40.55},
    {"x": 76.70, "y": 40.14},
    {"x": 80.00, "y": 40.00}
  ]

const greenLine = [
    {"x": 35.0, "y": 80.0},
    {"x": 35.15, "y": 76.28},
    {"x": 35.61, "y": 72.59},
    {"x": 36.38, "y": 68.95},
    {"x": 37.44, "y": 65.39},
    {"x": 38.79, "y": 61.92},
    {"x": 40.42, "y": 58.58},
    {"x": 42.33, "y": 55.39},
    {"x": 44.49, "y": 52.36},
    {"x": 46.89, "y": 49.52},
    {"x": 49.52, "y": 46.89},
    {"x": 52.36, "y": 44.49},
    {"x": 55.39, "y": 42.33},
    {"x": 58.58, "y": 40.42},
    {"x": 61.92, "y": 38.79},
    {"x": 65.39, "y": 37.44},
    {"x": 68.95, "y": 36.38},
    {"x": 72.59, "y": 35.61},
    {"x": 76.28, "y": 35.15},
    {"x": 80.00, "y": 35.00}
  ]
  
  export default ProjectRankingPlot;