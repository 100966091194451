import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import { useDispatch, useSelector } from 'react-redux';

import { changeHeight, changeWidth, toggleOpenCloseMenu } from '../../state/redux/actions/GeneralStateActions';
import '../../assets/css/NavBar.css';
import '../../assets/css/Logo.css';
import '../../assets/css/Menu.css';
import logo from '../../assets/images/royal-gold-logo-icon.png';
import home from '../../assets/images/home-white.png';
import { logoutUser, logoutUserWithNav } from '../../state/redux/actions/AuthActions';
import TermsOfUse from './TermsOfUse';

  {/* MOBILE Navigation Bar */}
  const MobileVersion = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {width, openCloseMenu} = useSelector((state: any) => state.general)
    const auth = useSelector((state: any) => state.auth)
    const menuRef = useRef<HTMLDivElement>(null);
    const [areYouSurePopup, setAreYouSurePopup] = useState(false)
    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false)

    const toggleMenu = () => {

        // change state
        dispatch(toggleOpenCloseMenu(!openCloseMenu))

        // change css for animation (refs required for animation transition)
        const ref = menuRef.current
        ref?.classList.toggle("is-menu-open")
    };

    const routeTo = (path: string) => {
        
        if(auth?.token){
            navigate(path);
        }

        dispatch(toggleOpenCloseMenu(false)); // Close the menu after navigation
        /// if menu open close it
        if(openCloseMenu){
            // change css for animation (refs required for animation transition)
            const ref = menuRef.current
            ref?.classList.toggle("is-menu-open")
        }
    };

    const logout = async () => {
       
        setAreYouSurePopup(false)
        dispatch(toggleOpenCloseMenu(false)); // Close the menu after navigation
        /// if menu open close it
        if(openCloseMenu){
            // change css for animation (refs required for animation transition)
            const ref = menuRef.current
            ref?.classList.toggle("is-menu-open")
        }
        await logoutUserWithNav(dispatch, navigate)

    };

    function handleBackgroundClick(event: any) {
        // Check if the click happened on the background and not on the container
        if (event.target === event.currentTarget) {
            // Close the popup
            setAreYouSurePopup(false)
        }
    }

    const openTerms = () => {
        setTermsOfUsePopup(true)
        toggleMenu()
    }

    return (
    <>
        <div className="navbar">
            <div className="nav-logo">
                <img src={logo} className="App-logo-no-spin" alt="logo" onClick={auth?.token != null ? ()=>{routeTo(`/home`)} : ()=>{routeTo(`/login`)}}/>
                <p className='nav-text'>
                    Royal Gold Utilities
                </p>
            </div>
            {auth?.token && 
            <div className="hamburger-menu" >
                <Hamburger toggled={openCloseMenu} toggle={toggleMenu} color='#F7F7F8'/>
            </div>}
        </div>

        {auth?.token && 
        <div ref={menuRef} className={"menu"}>
            <div className='menu-page'>
                
                <div onClick={()=>{routeTo(`/home`)}} className='menu-option margin-top'>
                    Home
                    <img src={home} className='icon-button margin-left'/>
                </div>
                
                {auth?.token != null ? 
                <div onClick={()=>{setAreYouSurePopup(true)}} className='menu-option button-profile-red margin-top'>
                    Log Out
                </div>
                : 
                <div onClick={()=>{routeTo(`/login`)}} className='menu-option button-profile margin-top'>
                    Log In
                </div>}
                
            </div>
            <div className='menu-footer'>
                <div className='menu-footer-option' onClick={()=>openTerms()}>terms of use</div>
            </div>
        </div>}

        {openCloseMenu &&
        <div onClick={()=>{toggleMenu()}} className={"menu-grey-background"} />}

        {areYouSurePopup &&
        <div className='logout-popup-background' onClick={(e)=>{handleBackgroundClick(e)}}>
            <div className='logout-popup-container'>
                <h2>Are you sure?</h2>
                <div onClick={()=>{setAreYouSurePopup(false)}} className='menu-option button-profile mt-10'>
                    Cancel
                </div>
                <div onClick={()=>{logout()}} className='menu-option button-profile-red mt-20'>
                    Log Out
                </div>
            </div>
        </div>}

        {termsOfUsePopup &&
        <div className='terms-popup'>
            <div className='exit-row-terms'>
                <Hamburger toggled={true} toggle={()=>{setTermsOfUsePopup(false)}} color='#F7F7F8'/>
            </div>
            <TermsOfUse/>
        </div>}
    </>
    );
  };

  export default MobileVersion;
