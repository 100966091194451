import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getproperty_names, get_property_configuration, save_property_configuration } from '../../state/redux/actions/components/PropertyConfigurationActions';
import "../../assets/css/PropertyConfiguration.css";

const PropertyConfigurationPage = () => {
  const dispatch = useDispatch();
  const { propertyNames, propertyConfiguration } = useSelector((state: any) => state.property_configuration);
  const { token } = useSelector((state: any) => state.auth);

  const [selectedProperty, setSelectedProperty] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [owner1Name, setOwner1Name] = useState('');
  const [owner1Percentage, setOwner1Percentage] = useState('');
  const [owner2Name, setOwner2Name] = useState('');
  const [owner2Percentage, setOwner2Percentage] = useState('');
  const [continent, setContinent] = useState('');
  const [country, setCountry] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [ciqNumber, setCiqNumber] = useState('');

  const continents = [
    'Africa',
    'Asia',
    'Australia',
    'Europe',
    'North America',
    'South America'
  ];

  useEffect(() => {
    if (token) {
      getproperty_names(dispatch);
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (propertyNames && propertyNames.length > 0 && !selectedProperty) {
      setSelectedProperty(propertyNames[0]);
    }
  }, [propertyNames, selectedProperty]);

  useEffect(() => {
    if (selectedProperty) {
      get_property_configuration(selectedProperty, dispatch);
    }
  }, [selectedProperty, dispatch]);

  useEffect(() => {
    if (selectedProperty && propertyConfiguration[selectedProperty]) {
      const config = propertyConfiguration[selectedProperty];
      setPropertyName(config.propertyName);
      setOwner1Name(config.owner1Name);
      setOwner1Percentage(config.owner1Percentage.toString());
      setOwner2Name(config.owner2Name);
      setOwner2Percentage(config.owner2Percentage.toString());
      setContinent(config.continent);
      setCountry(config.country);
      setStateProvince(config.stateProvince);
      setLongitude(config.longitude.toString());
      setLatitude(config.latitude.toString());
      setCiqNumber(config.ciqNumber);
    }
  }, [propertyConfiguration, selectedProperty]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  const handlePropertyChange = (value: string) => {
    setSelectedProperty(value);
  };

  const saveConfiguration = () => {
    const config = {
      propertyName,
      owner1Name,
      owner1Percentage: parseFloat(owner1Percentage),
      owner2Name,
      owner2Percentage: parseFloat(owner2Percentage),
      continent,
      country,
      stateProvince,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      ciqNumber
    };
    save_property_configuration(selectedProperty, config, dispatch);
  };

  const clearFields = () => {
    setPropertyName('');
    setOwner1Name('');
    setOwner1Percentage('');
    setOwner2Name('');
    setOwner2Percentage('');
    setContinent('');
    setCountry('');
    setStateProvince('');
    setLongitude('');
    setLatitude('');
    setCiqNumber('');
  };

  return (
    token && 
    <div className='background small-font'>
      <h1 className='margin-top' style={{textAlign: 'center'}}>Property Configuration</h1>

      <div className='property-config-container'>
        <div className='form-content'>
          <div style={{ width: '100%' }}>
            <div className='row-left-pcp'>
              <div className='column-1-pcp'>Select Property</div>
              <div className='column-2-pcp'>
                <select
                  className='dropdown-pcp'
                  value={selectedProperty}
                  onChange={(e) => handlePropertyChange(e.target.value)}
                >
                  <option value="">Select a property</option>
                  {propertyNames.map((name: string) => (
                    <option key={name} value={name}>{name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div style={{ width: '100%' }}>
            <div className='row-left-pcp'>
              <div className='column-1-pcp'>Property Name</div>
              <div className='column-2-pcp'>
                <input className='comments-pcp' type="text" value={propertyName} onChange={handleInputChange(setPropertyName)} />
              </div>
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Owner 1 Name</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="text" value={owner1Name} onChange={handleInputChange(setOwner1Name)} />
            </div>
          </div>
          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Owner 1 Percentage Ownership</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="number" min="0" max="100" value={owner1Percentage} onChange={handleInputChange(setOwner1Percentage)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Owner 2 Name</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="text" value={owner2Name} onChange={handleInputChange(setOwner2Name)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Owner 2 Percentage Ownership</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="number" min="0" max="100" value={owner2Percentage} onChange={handleInputChange(setOwner2Percentage)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Continent</div>
            <div className='column-2-pcp'>
              <select
                className='dropdown-pcp full-width'
                value={continent}
                onChange={(e) => setContinent(e.target.value)}
              >
                <option value="">Select a continent</option>
                {continents.map((cont) => (
                  <option key={cont} value={cont}>{cont}</option>
                ))}
              </select>
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Country</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="text" value={country} onChange={handleInputChange(setCountry)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>State/Province</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="text" value={stateProvince} onChange={handleInputChange(setStateProvince)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Longitude</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="number" step="any" value={longitude} onChange={handleInputChange(setLongitude)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>Latitude</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="number" step="any" value={latitude} onChange={handleInputChange(setLatitude)} />
            </div>
          </div>

          <div className='row-left-pcp'>
            <div className='column-1-pcp'>CIQ Number</div>
            <div className='column-2-pcp'>
              <input className='comments-pcp full-width' type="text" value={ciqNumber} onChange={handleInputChange(setCiqNumber)} />
            </div>
          </div>

        </div>
      </div>

      <div className='button-container-pcp'>
        <button className='button-item-pcp' onClick={saveConfiguration}>
          Save
        </button>
        <button className='button-item-pcp' onClick={clearFields}>
          Clear
        </button>
      </div>
    </div>
  );
};

export default PropertyConfigurationPage;
