import React from 'react';

const EconomicAssessmentPage = () => {
  return (
    <div className='background'>
      <h1 className='margin-top'>Economic Assessment</h1>
      <p>This is the content of GUI Page 3.</p>
    </div>
  );
};

export default EconomicAssessmentPage;
