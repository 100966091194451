// store.js
import {thunk} from 'redux-thunk';
import { general_state_reducer } from './GeneralStateReducer';
import { auth_reducer } from './AuthReducer';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { property_configuration_reducer } from './components/PropertyConfigurationReducer';
import { project_ranking_reducer } from './components/ProjectRankingReducer';

const rootReducer = combineReducers({
  general: general_state_reducer,
  auth: auth_reducer,
  property_configuration: property_configuration_reducer,
  project_ranking: project_ranking_reducer
});

const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // You can add options here if needed, like actionSanitizer, stateSanitizer, etc.
    }) : compose;

const enhancer = composeEnhancer(
    applyMiddleware(thunk)
);

export const store = createStore(rootReducer, enhancer);
