/*
This object holds all the state variables globally (similar to object oriented programming - but for JavaScript!). 
It keeps track of the variables and what they are in the app, which buttons have been clicked and so on. Each time a change is dispatched 
to the redux store, it forces a re-render (using variables called in the useSelector) so that everything looks and runs smoothly.
*/

interface PotentialData {
    Jurisdiction: number | null;
    AuEq_Annual_Production: number | null;
    Metal_Type: number | null;
    Gross_Margin: number | null;
    Company_Financial_Strength: number | null;
    Company_Team: number | null;
    Stage: number | null;
    Reserves: number | null;
    Resource_Conversion_Exploration: number | null;
    Permitting: number | null;
    Deposit_Type: number | null;
    Mine_Complexity: number | null;
    Process_Complexity: number | null;
    Infrastructure: number | null;
    ESG_Water: number | null;
    ESG_Community: number | null;
    ESG_GHG: number | null;
}

interface CommentsData {
    Jurisdiction_comments: string | null;
    Annualaueq_comments: string | null;
    Metaltype_comments: string | null;
    Grossmargin_comments: string | null;
    Companyfinstrength_comments: string | null;
    Companyteam_comments: string | null;
    Stage_comments: string | null;
    Reserves_comments: string | null;
    Upside_comments: string | null;
    Permitting_comments: string | null;
    Deposit_comments: string | null;
    Miningcomplexity_comments: string | null;
    Processingcomplexity_comments: string | null;
    Infrastructure_comments: string | null;
    ESG_Water_Comments: string | null;
    ESG_Community_Comments: string | null;
    ESG_GHG_Comments: string | null;
    ResourcePlotSize: number | null;
}

interface CurrentData {
    Jurisdiction: number | null;
    AuEq_Annual_Production: number | null;
    Metal_Type: number | null;
    Gross_Margin: number | null;
    Company_Financial_Strength: number | null;
    Company_Team: number | null;
    Stage: number | null;
    Reserves: number | null;
    Resource_Conversion_Exploration: number | null;
    Permitting: number | null;
    Deposit_Type: number | null;
    Mine_Complexity: number | null;
    Process_Complexity: number | null;
    Infrastructure: number | null;
    ESG_Water: number | null;
    ESG_Community: number | null;
    ESG_GHG: number | null;
}

export interface ProjectRankingData {
    potential_data: PotentialData | null;
    comments: CommentsData | null;
    current_data: CurrentData | null;
}

class ProjectRankingState {
    data: string | null;
    propertyNames: string[] | null;
    propertyName: string | null;
    propertyAssessmentNames: string[] | null;
    propertyAssessmentName: string | null;
    projectRankingData: ProjectRankingData | null;
    initialProjectRankingData: ProjectRankingData | null;
    otherDataFlag: boolean;

    constructor() {
        this.data = null;
        this.propertyNames = null;
        this.propertyName = null;
        this.propertyAssessmentNames = null;
        this.propertyAssessmentName = null;
        this.projectRankingData = null;
        this.initialProjectRankingData = null;
        this.otherDataFlag = false; 
    }
}

export { ProjectRankingState };
