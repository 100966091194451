import React from 'react';

interface PDFViewerProps {
  pdfUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfUrl }) => {
  return (
    <iframe
      src={pdfUrl}
      style={{ width: '100%', height: '100vh', border: 'none', position:'absolute', top:0,left:0 }}
      title="PDF Viewer"
    />
  );
};

export default PDFViewer;
