// AUTH
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_TOKEN_ID = 'SET_TOKEN_ID';

export const IS_LOADING_LOGIN = 'IS_LOADING_LOGIN';
export const IS_LOADING_LOGOUT = 'IS_LOADING_LOGOUT';
export const IS_LOADING_REFRESH = 'IS_LOADING_REFRESH';
export const IS_LOADING_VALIDATE = 'IS_LOADING_VALIDATE';
export const SET_AUTH_ERROR_MESSAGE = 'SET_AUTH_ERROR_MESSAGE';
export const SET_USERNAME = 'SET_USERNAME';

// GENERAL
export const CHANGE_HEIGHT = 'CHANGE_HEIGHT';
export const CHANGE_WIDTH = 'CHANGE_WIDTH';
export const TOGGLE_OPEN_CLOSE_MENU = 'TOGGLE_OPEN_CLOSE_MENU';
export const REROUTE_APP = 'REROUTE_APP';
export const REFRESH_COUNT = 'REFRESH_COUNT'

// PROPERTY_ASSESSMENT_DATA
export const SET_PROPERTY_ASSESSMENT_DATA = 'SET_PROPERTY_ASSESSMENT_DATA';

// PROPERTY_NAMES
export const SET_PROPERTY_NAMES = 'SET_PROPERTY_NAMES';
export const SET_PROPERTY_CONFIGURATION = 'SET_PROPERTY_CONFIGURATION'; 


// PROPERTY_NAMES_PROJECT_RANKING
export const SET_PROPERTY_NAMES_PR = 'SET_PROPERTY_NAMES_PR';
export const SET_PROPERTY_NAME_PR = 'SET_PROPERTY_NAME_PR';
export const SET_PROPERTY_ASSESSMENT_NAME_PR = 'SET_PROPERTY_ASSESSMENT_NAME_PR';
export const SET_PROPERTY_ASSESSMENT_NAMES_PR = 'SET_PROPERTY_ASSESSMENT_NAMES_PR';
export const SET_PROJECT_RANKING_DATA = 'SET_PROJECT_RANKING_DATA';
export const SET_INITIAL_PROJECT_RANKING_DATA = 'SET_INITIAL_PROJECT_RANKING_DATA';
export const SET_OTHER_DATA_FLAG_PR = 'SET_OTHER_DATA_FLAG_PR';