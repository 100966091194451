/*
This object holds all the state variables globally (similar to object oriented programming - but for JavaScript!). 
It keeps track of the variables and what they are in the app, which buttons have been clicked and so on. Each time a change is dispatched 
to the redux store, it forces a re-render (using variables called in the useSelector) so that everything looks and runs smoothly.
*/

class GeneralState {
    height: number;
    width: number;
    openCloseMenu: boolean;
    reroute: string | null;
    refreshCount: number;

    /*
    Initialize the variables
    */
    constructor() {
        this.height = 0;
        this.width = 0;
        this.openCloseMenu = false;
        this.reroute = null;
        this.refreshCount = 0;
    }
}

export { GeneralState };
