import * as actionType from '../../actions/ActionTypes';
import { ProjectRankingState } from '../../model/components/ProjectRankingState';

export const project_ranking_reducer = (state = new ProjectRankingState(), action: any): ProjectRankingState => {

  switch (action.type) {

    case actionType.SET_PROPERTY_NAMES_PR:
      return {
        ...state,
        propertyNames: action.payload?.state,
      };
    
    case actionType.SET_PROPERTY_NAME_PR:
      return {
        ...state,
        propertyName: action.payload?.state,
    };
    case actionType.SET_PROPERTY_ASSESSMENT_NAMES_PR:
      return {
        ...state,
        propertyAssessmentNames: action.payload?.state,
    };
    case actionType.SET_PROPERTY_ASSESSMENT_NAME_PR:
      return {
        ...state,
        propertyAssessmentName: action.payload?.state,
    };
    case actionType.SET_PROJECT_RANKING_DATA:
      return {
        ...state,
        projectRankingData: action.payload?.state,
    };
    case actionType.SET_INITIAL_PROJECT_RANKING_DATA:
      return {
        ...state,
        initialProjectRankingData: action.payload,
    };
    case actionType.SET_OTHER_DATA_FLAG_PR:
      return {
        ...state,
        otherDataFlag: action.payload,
    };
    default:
      return state;
  }
};
