import React, { useEffect, useState } from 'react';
import PDFViewer from './PDFViewer';
import * as api from '../../../state/api/api'; // Your API helper

const PDFContainer: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string>('');
  
  const PDF_MIME_TYPE = 'application/pdf';

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const response = await api.postRequest('/api/files/scoring-guidelines', {}, true);
        
        if (response) {
          // Create a Blob URL for the PDF
          const pdfBlob = new Blob([response.data], { type: PDF_MIME_TYPE });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          
          setPdfUrl(pdfUrl);
        }
      } catch (error) {
        console.error('Failed to fetch PDF:', error);
      }
    };

    fetchPDF();
  }, []);

  if (!pdfUrl) {
    return <div></div>;
  }

  return <PDFViewer pdfUrl={pdfUrl} />;
};

export default PDFContainer;
